




































import { Component, Vue } from 'vue-property-decorator';
import * as OM from '@/Model';
import TemaCintura from './temaCintura.vue';
import { Chrome } from 'vue-color';
import { CinturaClient, TemaClient } from '@/services/Services';

@Component({
    components: {
        TemaCintura,
        ChromePicker: Chrome
    }
})
export default class EditCinturaView extends Vue {
    cintura: OM.Cintura = new OM.Cintura();
    temi: OM.Tema[] = [];
    
    color: string = "#ffffff";
    setCinturaColor(val: any){
        this.cintura.hexColor = val.hex;
    }

    created(){
        if(this.$route.params.identifier != "0"){
            CinturaClient.getById(this.$route.params.identifier)
            .then(x => {
                this.cintura = x;
                this.color = this.cintura.hexColor;
                TemaClient.getByCinturaIdentifier(this.cintura.identifier)
                .then(t => {
                    this.temi = t;
                })
            })
        }
    }

    save(){
        CinturaClient.save(this.cintura)
        .then(x => {
            if(x)
                this.cintura.identifier = x;
            if(this.$route.params.identifier == "0")
                this.$router.replace("/app/cinture/" + x);
        })
    }

    addTema(){
        let newTema = new OM.Tema();
        newTema.nome = "Tema " + (this.temi.length + 1).toString();
        this.temi.push(newTema);
    }

    removeTema(tema: OM.Tema){
        let ind = this.temi.findIndex(x => x == tema);
        this.temi.splice(ind, 1);
        TemaClient.delete(tema.identifier);
    }

    openLezioni(tema: OM.Tema){
        this.$router.push("/app/lezioni/" + tema.identifier);
    }
}
