


















































import { Component, Vue } from 'vue-property-decorator';
import { routes } from '@/router';
import * as OM from '@/Model';
import store from '@/store';
import toastr from 'toastr';
import { setInterval } from 'timers';
import { AssetClient, GlobalConfigClient, SimulationClient } from '@/services/Services';

@Component
export default class LayoutView extends Vue {
    routes = routes;

    globalConfig: OM.GlobalConfig = new OM.GlobalConfig();
    showDateInput: boolean = false;
    sendPushOnAdvance: boolean = true;

    created(){
        GlobalConfigClient.getConfig()
        .then(x => {
            this.globalConfig = x;
        })
        setInterval(this.fetchSystemDate, 30000);
        AssetClient.getValuteValues().then(x => {
            store.state.valute.EURUSD = x['EURUSD'];
            store.state.valute.USD = x['EURUSD'];
            store.state.valute.EURGBP = x['EURGBP'];
            store.state.valute.GBP = x['EURGBP'];
            store.state.valute.EURCHF = x['EURCHF'];
            store.state.valute.CHF = x['EURCHF'];
            store.state.valute.EURJPY = x['EURJPY'];
            store.state.valute.JPY = x['EURJPY'];
        })
    }

    fetchSystemDate(){
        GlobalConfigClient.getSystemDate(false)
        .then(x => {
            if(!this.showDateInput)
                this.globalConfig.systemDate = x;
        })
    }

    setGlobalConfig(){
        GlobalConfigClient.saveConfig(this.globalConfig)
        .then(x => {
            toastr.success("Salvato");
            this.showDateInput = false;
        })
    }

    get showSpinner(){
        return store.state.showSpinner > 0;
    }

    advanceDay(){
        SimulationClient.advanceOneDay(this.sendPushOnAdvance)
        .then(x => {
            store.state.dateAdvancedCallbacks.forEach(x => {
                x();
            });
            this.fetchSystemDate();
        })
        .catch(x => {
            toastr.error("non ok");
        });
    }
}
