































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import * as OM from '@/Model';
import toastr from 'toastr';
import { PushNotificationClient } from '@/services/Services';

@Component
export default class CreaOrdineModal extends Vue {
    
    @Prop({
        type: [Array],
    }) userIds: string[];

    vm: OM.SendPushConfigVm = new OM.SendPushConfigVm();

    sendPush(){
        this.vm.userIds = this.userIds;
        PushNotificationClient.sendPushNotification(this.vm)
        .then(x => {
            toastr.success('Notifiche push inviate');
            this.$emit('close');
        })
    }

}
