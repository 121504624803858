
















import { Component, Vue } from 'vue-property-decorator';
import { CommonServices } from '@/services/common';
import { AuthClient } from '@/services/Services';
import store from '@/store';

@Component
export default class LoginView extends Vue {
    email: string = "";
    password: string = "";
    login(){
        store.state.showSpinner++;
        AuthClient.loginWithVersion({
           email: this.email, 
           password: this.password, 
           appVersion: "99.99.99"
        })
        .then(x => {
            CommonServices.setAuthToken(x.token);
            CommonServices.setLoginData(x.token);
            this.$router.push('app/')
        })
        .finally(() => store.state.showSpinner--)
    }
}
