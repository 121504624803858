


















import { Component, Vue, Prop } from 'vue-property-decorator';
import * as OM from '@/Model';
import * as VM from '@/viewModel';
import * as AgGrid from 'ag-grid-vue';
import AgLinkDetail from '@/components/tableComponents/agLinkDetail.vue'

@Component({
    components: {
        AgGridVue: AgGrid.AgGridVue,
        AgLinkDetail
    }
})
export default class PremiTable extends Vue {
    @Prop({
        type: Array
    }) rowData: OM.PremiUserGroupedVm[];

    columnDefs: any[] = [
        { headerName: 'Periodo', field: 'periodo' },
        { headerName: 'Challenge', field: 'challengeName'},
        { headerName: 'Numero premi', field: 'numeroPremi' },
    ];
    gridOptions: any = {
        context: {
            componentParent: this
        }
    };
    
    fullTextFilter: string = "";
    onFilterTextBoxChanged(){
        this.gridOptions.api.setQuickFilter(this.fullTextFilter);
    }
    mounted(){
        this.gridOptions.api.sizeColumnsToFit();
        window.addEventListener('resize', this.onResize);
    }
    onResize(){
        setTimeout(() => {
            this.gridOptions.api.sizeColumnsToFit();
        });
    }
    destroyed(){
        window.removeEventListener('resize', this.onResize);
    }
    
}
