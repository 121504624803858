






























import { Component, Vue } from 'vue-property-decorator';
import * as OM from '@/Model';
import * as VM from '@/viewModel';
import TemplateModal from './templateModal.vue';
import { TemplateClient } from '@/services/Services';

@Component
export default class Templates extends Vue {

    templates: OM.Template[] = [];
    contentVariables: string[] = [];


    created(){
        TemplateClient.getContentVariables()
        .then(x => {
            this.contentVariables = x;
        })
        TemplateClient.getAll()
        .then(x => {
            this.templates = x;
        })
    }

    createNewTemplate(){
        let tpl = new OM.Template();
        this.openModal(tpl);
    }
    
    openModal(item: OM.Template){
        this.$opModal.show(TemplateModal, {
            template: item,
            contentVariables: this.contentVariables, 
            callback: (updatedTemplate: OM.Template) => {
                TemplateClient.save(updatedTemplate)
                .then(x => {
                    TemplateClient.getAll()
                    .then(x => {
                        this.templates = x;
                    })
                })
            }
        })
    }

}
