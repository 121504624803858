



























import { Component, Vue, Prop } from 'vue-property-decorator';
@Component
export default class Accordion extends Vue {
    @Prop() open: boolean;
    @Prop({
        default: false
    }) indent: boolean;
    openThis: boolean = false;
    created(){
        if(this.open){
            this.openThis = true;
        }
    }
}

