import { LoginResponse } from '@/Model';
import * as VM from '@/viewModel'

class _StorageServices {
    private readFromLocalStorage<T>(lsName: string): T | null {
        let data = window.localStorage.getItem(lsName);
        if(data){
            return JSON.parse(data);
        } else {
            return null;
        }
    }

    setLoginCredentials(data: LoginResponse){
        window.localStorage.setItem('LoginCredentials', JSON.stringify(data));
    };
    getLoginCredentials(){
        let text = window.localStorage.getItem('LoginCredentials');
        if(text){
            return <LoginResponse>JSON.parse(text);
        }
    };

    getLastChatTimestamp() {
        return this.readFromLocalStorage<number>('lastChatTimestamp');
    }
    setLastChatTimestamp(timestamp: number) {
        window.localStorage.setItem('lastChatTimestamp', JSON.stringify(timestamp));
    }

    setOpTableFilters(key: string, filters: any){
        window.localStorage.setItem('_op_' + key, JSON.stringify(filters));
    }
    getOpTableFilters(key: string){
        return this.readFromLocalStorage<any>('_op_' + key);
    }
    
}
export let StorageServices = new _StorageServices();