


























































import { Component, Vue } from 'vue-property-decorator';
import { CaricamentoFileServices } from '@/services/CaricamentoFileServices';
import * as OM from '@/Model';
import FileSelect from '@/components/fileSelect.vue';
import StringListModal from '@/components/stringListModal.vue';

@Component({
    components: {
        FileSelect
    }
})
export default class CaricamentoFile extends Vue {

    fileLezioni: any = null;
    fileGlossario: any = null;
    fileAsset: any = null;
    fileAssetAndNav: any = null;
    untilNavs: string = new Date(2999, 11, 31).toDateString();
    fileOrdini: any = null;
    fileValute: any = null;
    messageLezioni: string = "";
    messageGlossario: string = "";
    messageAsset: string = "";
    messageAssetAndNav: string = "";
    messageOrdini: string = "";

    uploadLezioni(){
        this.messageLezioni = "";
        CaricamentoFileServices.LoadLezioni({ file: this.fileLezioni })
        .then(x => {
            this.messageLezioni = "Lezioni caricate";
        })
        .catch(err => {
            this.messageLezioni = "Errore nel caricamento";
        });
    }
    
    uploadGlossario(){
        this.messageGlossario = "";
        CaricamentoFileServices.LoadGlossario({ file: this.fileGlossario })
        .then(x => {
            this.messageGlossario = "Glossario caricato";
        })
        .catch(err => {
            this.messageGlossario = "Errore nel caricamento";
        });
    }
    
    uploadAssetAndNav(){
        this.messageAsset = "";
        CaricamentoFileServices.LoadAssetsWithNav({
            file: this.fileAssetAndNav, 
            untilDate: this.untilNavs
        })
        .then(x => {
            this.$opModal.show(StringListModal, {
                title: 'errori',
                stringList: x
            })
            this.messageAssetAndNav = "Finito";
        })
        .catch(err => {
            this.messageAssetAndNav = "ERRORE";
        });
    }
    
    uploadAsset(){
        this.messageAsset = "";
        CaricamentoFileServices.LoadAssets({ file: this.fileAsset })
        .then(x => {
            this.$opModal.show(StringListModal, {
                title: 'errori',
                stringList: x
            })
            this.messageAsset = "Finito";
        })
        .catch(err => {
            this.messageAsset = "ERRORE";
        });
    }

    uploadOrdiniUtentiPortafogli(){
        this.messageOrdini = "";
        CaricamentoFileServices.LoadOrdiniUtentiPortafogli({ file: this.fileOrdini })
        .then(x => {
            this.messageOrdini = "Ordini caricati";
        })
        .catch(err => {
            this.messageOrdini = "Errore nel caricamento";
        });
    }

}
