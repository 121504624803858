










































import { Component, Vue, Prop } from 'vue-property-decorator';
import * as OM from '@/Model';

@Component
export default class DomandaLezione extends Vue {

    @Prop({
        type: [ OM.Domanda, Object ]
    }) domanda: OM.Domanda;

    addRisposta(){
        this.domanda.risposte.push(new OM.Risposta());
    }

    removeRisposta(i: number){
        this.domanda.risposte.splice(i, 1);
    }

}
