













































































import { Component, Vue } from 'vue-property-decorator';
import { routes } from '@/router';
import * as OM from '@/Model';
import toastr from 'toastr';
import QuestionarioInizialeOptions from '@/components/questionarioInizialeOptions.vue';
import Accordion from '@/components/accordion.vue';
import FileSelect from '@/components/fileSelect.vue';
import { GlobalConfigClient } from '@/services/Services';
import { CaricamentoFileServices } from '@/services/CaricamentoFileServices';


@Component({
    components: {
        QuestionarioInizialeOptions,
        Accordion,
        FileSelect
    }
})
export default class QuestionarioInizialeView extends Vue {

    config: OM.GlobalConfig = new OM.GlobalConfig();
    questionarioInizialeConfig: OM.QuestionarioInizialeConfig = new OM.QuestionarioInizialeConfig();
    
    fileComuni: File = null;

    created(){
        GlobalConfigClient.getConfig()
        .then(x => {
            this.config = x;
            this.questionarioInizialeConfig = this.config.questionarioInizialeConfig || new OM.QuestionarioInizialeConfig();
        })
    }

    uploadFileComuni(geoNumber){
        CaricamentoFileServices.CaricaComuni({
            file: this.fileComuni
        })
        .then(x => {
            toastr.success('File caricato');
        })
    }

    saveConfig(){
        GlobalConfigClient.saveConfig(this.config)
        .then(x => {
            toastr.success("Salvato!");
            this.config = x;
        })
    }

}
