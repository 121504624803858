import { CommonServices } from "@/services/common";
import { baseUrl } from "@/config";
import * as OM from '@/Model';
import * as VM from '@/viewModel';

class _CaricamentoFileServices {

    LoadLezioni(vm: VM.UploadFileVm){
        return CommonServices.uploadFileToUrl<void>(baseUrl + "api/CaricamentoFile/LoadLezioni", vm);
    }

    LoadGlossario(vm: VM.UploadFileVm){
        return CommonServices.uploadFileToUrl<void>(baseUrl + "api/CaricamentoFile/LoadGlossario", vm);
    }

    LoadAssetsWithNav(vm: VM.UploadFileVm){
        return CommonServices.uploadFileToUrl<string[]>(baseUrl + "api/CaricamentoFile/LoadAssetsWithNav", vm);
    }

    LoadAssets(vm: VM.UploadFileVm){
        return CommonServices.uploadFileToUrl<string[]>(baseUrl + "api/CaricamentoFile/LoadAssets", vm);
    }

    LoadValute(vm: VM.UploadFileVm){
        return CommonServices.uploadFileToUrl<void>(baseUrl + "api/CaricamentoFile/LoadValute", vm);
    }

    LoadOrdiniUtentiPortafogli(vm: VM.UploadFileVm){
        return CommonServices.uploadFileToUrl<void>(baseUrl + "api/CaricamentoFile/LoadOrdiniUtentiPortafogli", vm);
    }
    
    LoadNavsUntilDate(vm: VM.UploadFileVm){
        return CommonServices.uploadFileToUrl<void>(baseUrl + "api/CaricamentoFile/LoadNavsUntilDate", vm);
    }

    LoadNavsUntilDateAndAdvance(vm: VM.UploadFileVm){
        return CommonServices.uploadFileToUrl<void>(baseUrl + "api/CaricamentoFile/LoadNavsUntilDateAndAdvance", vm);
    }

    CaricaComuni(vm: VM.UploadFileVm){
        return CommonServices.uploadFileToUrl<void>(baseUrl + "api/GlobalConfig/CaricaComuni", vm);
    }

    UploadImageForLesson(vm: VM.UploadFileVm){
        return CommonServices.uploadFileToUrl<void>(baseUrl + "api/ImageForLesson/UploadImage", vm);
    }

    UploadLezioneAudio(vm: VM.UploadFileVm){
        return CommonServices.uploadFileToUrl<string>(baseUrl + "api/Lezione/UploadLezioneAudio", vm);
    }

    

}

export let CaricamentoFileServices = new _CaricamentoFileServices();