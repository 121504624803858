






















import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import * as VM from '@/viewModel';
import { StorageServices } from '@/services/StorageServices';
import store from '@/store';
import { PortafoglioClient } from '@/services/Services';

@Component({
    components: {
    }
})
export default class WalletBar extends Vue {

    @Prop({
        type: Number,
        default: 10
    }) matchRisk: number;

    @Prop({
        type: Number,
        required: false
    }) walletRisk: number;
    
    @Prop({
        type: Number,
        required: false,
        default: 0
    }) sumToLiquidity: number;
    
    @Watch('walletRisk')
    onWalletRiskChange(next, prev){
        store.state.portafoglioStatus.walletRisk = next;
    }

    get percWalletRisk(){
        return store.state.portafoglioStatus.walletRisk * 100;
    }
    get percLiquidityPercentage(){
        return store.state.portafoglioStatus.liquidityPercentage * 100;
    }
    get totalLiquidity(){
        return store.state.portafoglioStatus.liquidity + this.sumToLiquidity;
    }

    created(){
        if(!this.$route.params.portafoglioIdentifier){
            console.error('Cannot use walletBar component on this route, missing param "portafoglioIdentifier"');
            return;
        }
        PortafoglioClient.getPortafoglioStatusWithSwitch({
            portafoglioIdentifier: this.$route.params.portafoglioIdentifier,
            creaSwitchVm: null
        })
        .then(x => {
            store.state.portafoglioStatus = x;
            if(this.walletRisk){
                store.state.portafoglioStatus.walletRisk = this.walletRisk;
            }
        })
    }

}
