







































import { Component, Vue, Prop } from 'vue-property-decorator';
import * as OM from '@/Model';
import * as VM from '@/viewModel';
import store from '@/store';
import linkify from 'linkifyjs/html';
import ChatMessage from '@/components/chatMessage.vue';
import { ChatClient } from '@/services/Services';

@Component({
    components: {
        ChatMessage
    }
})
export default class Chat extends Vue {

    @Prop() chatIdentifier: string;

    chatRoom: OM.ChatRoomVm = new OM.ChatRoomVm();
    messages: OM.ChatMessage[] = [];
    quotedMessage: OM.QuotedMessage = null;
    userId: string = store.state.loginData.userIdentifier;
    messageText: string = "";
    showNuoviMessaggi: boolean = false;
    messageContainer: Element;
    scrollListenerOn: boolean;    
    isTicket: boolean = false;
    fetchingData: boolean = false;
    doneFetching: boolean;
    take: number = 20;

    isIos = false;

    pollingInterval: any;
    created(){
        this.isIos = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
    }

    messageContainerStyle: string = "";
    calcMessageContainerStyle(){
        let ris = '';
        if(this.isIos)
            ris += '-webkit-overflow-scrolling: touch;';
        if(this.quotedMessage){
            let quotedContainer = document.querySelector('.chatWriter .quotedMessage');
            let height = getComputedStyle(quotedContainer).height;
            let toSubtract = 106 + parseFloat(height.substring(0, height.length - 2));
            ris += 'height: calc(100% - ' + toSubtract + 'px);';
        }
        this.messageContainerStyle = ris;
        if(ris && this.isAtBottom())
            setTimeout(() => this.goToBottom(), 10);
    }
    mounted(){
        this.messageContainer = <Element>document.querySelector('.chatMessageContainer');
        ChatClient.getChatMessages(this.chatIdentifier, this.messages.length, this.take, 1)
        .then(msgs => {
            this.messages = msgs;
            this.$nextTick( () => {
                this.messageContainer.scrollTop = this.messageContainer.scrollHeight;
                this.messageContainer.addEventListener('scroll', this.onTopScroll);
            });
            this.pollingInterval = setInterval(() => {
                ChatClient.getMessagesFrom(this.chatIdentifier, this.messages[this.messages.length - 1].timestamp)
                .then(x => {
                    if(x.length > 0){
                        this.messages.push(...x);
                        if(this.isAtBottom()){
                            setTimeout(() => {
                                this.goToBottom();
                            }, 10);
                        } else {
                            //nuovi messaggi
                            this.showNuoviMessaggi = true;
                            this.scrollListenerOn = true;
                            this.messageContainer.addEventListener('scroll', this.scrollListener);
                        }
                    }
                });
            }, 5000);
        })
    }

    onLongtouch(val: OM.ChatMessage){
        this.quotedMessage = {
            sender: val.senderName,
            text: val.text,
        };
        this.$nextTick(() => {
            this.calcMessageContainerStyle();
        });
    }

    cancelQuote(){
        this.quotedMessage = null;
        this.calcMessageContainerStyle();
    }

    addReturn(){
        this.messageText += `
        `;
    }

    onTopScroll(){
        if(!this.isAtTop())
            return;
        if(this.fetchingData || this.doneFetching)
            return;
        this.fetchingData = true;
        ChatClient.getChatMessages(this.chatIdentifier, this.messages.length, this.take, 1)
        .then(msgs => {
            this.fetchingData = false;
            let scrollBottom = this.messageContainer.scrollHeight - this.messageContainer.scrollTop;
            this.messages.unshift(...msgs);
            this.$nextTick(() => {
                this.messageContainer.scrollTop = this.messageContainer.scrollHeight - scrollBottom;
            });
            if(msgs.length == 0){
                this.doneFetching = true;
                this.messageContainer.removeEventListener('scroll', this.onTopScroll);
            }
        })
    }
    
    destroyed(){
        clearInterval(this.pollingInterval);
        this.messageContainer.removeEventListener('scroll', this.scrollListener);
        this.messageContainer.removeEventListener('scroll', this.onTopScroll);
    }
    
    onMessage(message: OM.ChatMessage){
        this.messages.push(message);
        if(this.isAtBottom()){
            setTimeout(() => {
                this.goToBottom();
            }, 10);
        } else {
            //nuovi messaggi
            this.showNuoviMessaggi = true;
            this.scrollListenerOn = true;
            this.messageContainer.addEventListener('scroll', this.scrollListener);
        }
    }

    isAtTop(){
        return this.messageContainer.scrollTop == 0;
    }

    isAtBottom(){
        let a = Math.abs(Math.floor(this.messageContainer.clientHeight - this.messageContainer.scrollHeight));
        let b = Math.floor(this.messageContainer.scrollTop + 10);
        return a <= b;
    }
    scrollListener(){
        if(this.isAtBottom()){
            this.showNuoviMessaggi = false;
            this.scrollListenerOn = false;
            this.messageContainer.removeEventListener('scroll', this.scrollListener);
        }
    }

    sendMessage(){
        if(!this.messageText.trim()) {
            return;
        }
        let parsedText = linkify(this.messageText);
        let newMessage: OM.SendMessageVm;
        newMessage = {
            chatRoomIdentifier: this.chatIdentifier,
            text: parsedText,
            senderName: store.state.loginData.nomeUtente,
            senderIdentifier: store.state.loginData.userIdentifier,
            quotedMessage: this.quotedMessage
        }
        ChatClient.sendMessage(newMessage)
        .then(x => {
            this.messages.push(x);
            this.messageText = "";
            this.quotedMessage = null;
            this.calcMessageContainerStyle();
            this.$nextTick(() => {
                this.goToBottom();
            });
        });
    }

    goToBottom(){
        this.messageContainer.scrollTop = this.messageContainer.scrollHeight;
    }
}
