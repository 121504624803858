








































import { Component, Vue } from 'vue-property-decorator';
import * as OM from '@/Model';
import * as VM from '@/viewModel';
import AssetsTable from './assetsTable.vue';
import { AssetClient } from '@/services/Services';

@Component({
    components: {
        AssetsTable
    }
})
export default class AssetsView extends Vue {

    assets: OM.AssetVm[] = [];

    created(){
        AssetClient.getAll()
        .then(x => {
            this.assets = x;
        })
    }

}
