























































import { Component, Vue } from 'vue-property-decorator';
import * as OM from '@/Model';
import * as VM from '@/viewModel';
import UploadImmaginiModal from './uploadImmaginiModal.vue';
import toastr from 'toastr';
import { ImageForLessonClient } from '@/services/Services';

@Component
export default class GalleriaImmaginiView extends Vue {

    items: OM.ImageForLessonGroupVm[] = [];

    created(){
        this.init();
    }

    init(){
        ImageForLessonClient.getAllGrouped()
        .then(x => {
            this.items = x;
        })
    }

    deleteImage(item: VM.ImageForLessonVm){
        if(!confirm("Confermi la rimozione?")){
            return;
        }
        
        ImageForLessonClient.deleteByName(item.fileName)
        .then(() => {
            this.init();
            toastr.success("Eliminato");
        });
    }
    
    openModal(){
        this.$opModal.show(UploadImmaginiModal, {
        }, () => {
            this.init();
        })
    }

    copyUrl(item: VM.ImageForLessonVm){
        let copyInput: any = this.$refs[item.fileName][0];
        copyInput.select();
        document.execCommand("copy");
        toastr.success("Copiato");
    }

}
