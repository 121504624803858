






















import { Component, Vue, Prop } from 'vue-property-decorator';
import * as OM from '@/Model';
import { GlobalConfigClient } from '@/services/Services';

@Component
export default class ChatConfigModal extends Vue {
    
    localItem: OM.GlobalConfig = new OM.GlobalConfig();
    
    created(){
        GlobalConfigClient.getConfig()
        .then(x => {
            this.localItem = x;
        })
    }

    save(){
        GlobalConfigClient.saveConfig(this.localItem)
        .then(x => {
            this.$emit('close');
        });
    }

}
