



























































import { Component, Vue, Prop } from 'vue-property-decorator';
import * as VM from '@/viewModel';
import * as OM from '@/Model';
import * as Enum from '@/enum';

@Component
export default class NodeModal extends Vue {
    
    @Prop({
        type: [Object, OM.EditNodeVm]
    }) node: OM.EditNodeVm;
    
    @Prop({
        required: true
    }) callback: any;

    sendPush: boolean = false;
    pushConfig: OM.SendPushConfigVm = new OM.SendPushConfigVm();

    backup: OM.EditNodeVm = null;

    compareOperators: any = null;
    created(){
        this.compareOperators = Enum.EnumToList(Enum.CompareOperator);
        this.backup = { ...this.node };
    }

    annulla(){
        for(let prop in this.backup){
            this.node[prop] = this.backup[prop];
        }
        this.$emit('close');
    }

    save(){
        this.callback(this.node, this.sendPush && this.pushConfig);
        this.$emit('close');
    }

}
