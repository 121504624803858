










import { Component, Vue, Prop } from 'vue-property-decorator';
import * as OM from '@/Model';
import { TemaClient } from '@/services/Services';

@Component
export default class TemiCintura extends Vue {

    @Prop() cinturaColor: string;
    @Prop({
        type: [ OM.Tema, Object ]
    }) tema: OM.Tema;


    hasChanged: boolean = false;
    saveTema(){
        if(!this.hasChanged)
            return;
        TemaClient.save(this.tema)
        .then(x => {
            Vue.set(this.tema, 'identifier', x);
            this.hasChanged = false;
        });
    }

    emitLezioni(){
        this.$emit("lezioni", this.tema);
    }
    
    emitRemove(){
        this.$emit("remove", this.tema);
    }

}
