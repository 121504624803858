

























import { Component, Vue, Prop } from 'vue-property-decorator';
import * as OM from '@/Model';

@Component
export default class AssetInPortafoglioTable extends Vue {
    @Prop({
        type: Array
    }) rowData: OM.AssetInPortafoglioVm[];

}
