















import { Component, Vue, Prop } from 'vue-property-decorator';
import * as OM from '@/Model';

@Component
export default class StringListModal extends Vue {
    
    @Prop() title: string;
    @Prop() stringList: string[];

}
