





import { Component, Vue } from 'vue-property-decorator';

@Component
export default class AbilitaPolly extends Vue {
    
    togglePolly(){
        let self: any = this;
        self.params.context.componentParent.togglePolly(self.params.data);
    }
}
