







import { Component, Vue } from 'vue-property-decorator';
import FileSelect from '@/components/fileSelect.vue'

@Component({
    components: {
        FileSelect
    }
})
export default class PollyUrl extends Vue {
    
    uploadAudio(file){
        if(!confirm("Vuoi caricare questo file?"))
            return;
            
        let self: any = this;
        self.params.context.componentParent.uploadAudio(self.params.data, file);
    }

}
