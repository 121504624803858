





import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import * as OM from '@/Model';
import Chartist from 'chartist/dist/chartist.min.js';

@Component
export default class DateValueChart extends Vue {

    @Prop({
        type: [Array]
    }) inputs: OM.DateValue[];

    chartist: any;
    @Watch('inputs')
    onInputsChange(next, prev){
        this.createChart();
    }

    mounted(){
        this.createChart();
        this.chartist.on('created', this.createBorder); 
    }
    createChart(){
        this.inputs.forEach(x => {
            x.date = new Date(x.date).toDateString();
        })
        var data = {
            labels: this.inputs.map(x => x.date),
            series: [
                {
                    data: this.inputs.map(x => x.value)
                }
            ]
        };
        var options = {
            showPoint: false,
            axisX: {
                showLabel: false,
                showGrid: false,
            },
            axisY: {
                showLabel: false,
                showGrid: false,
            }
        }
        this.chartist = new Chartist.Line(this.$refs.chartElement, data, options);
    }
    createBorder(context){
        context.svg.elem('rect', {
            x: context.chartRect.x1,
            y: context.chartRect.y2,
            width: context.chartRect.width(),
            height: context.chartRect.height(),
            fill: 'none',
            stroke: 'rgba(0,0,0,0.5)',
            'stroke-width': '1px'
        })
    }
    destroyed(){
        this.chartist.off('created', this.createBorder);
    }
}
