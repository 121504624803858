
































































import { Component, Vue } from 'vue-property-decorator';
import * as OM from '@/Model';
import CreaPortafoglioModal from './creaPortafoglioModal.vue';
import { PortafoglioClient, UserClient } from '@/services/Services';

@Component({
    components: {
    }
})
export default class UserDetailView extends Vue {

    user: OM.User = null;
    portafogli: OM.Portafoglio[] = [];


    created(){
        let proms = [];
        proms.push(UserClient.getById(this.$route.params.identifier));
        proms.push(PortafoglioClient.getByUserIdentifier(this.$route.params.identifier));
        Promise.all(proms)
        .then(x => {
            this.user = x[0];
            this.portafogli = x[1];
        })
    }

    openCreaPortafoglioModal(){
        this.$opModal.show(CreaPortafoglioModal, {
            userIdentifier: this.$route.params.identifier
        }, () => {
            PortafoglioClient.getByUserIdentifier(this.$route.params.identifier)
            .then(x => {
                this.portafogli = x;
            })
        });
    }

    deletePortafoglio(item: OM.Portafoglio){
        if(!confirm("Sei sicuro?"))
            return;
            
        PortafoglioClient.delete(item.identifier)
        .then(x => {
            PortafoglioClient.getByUserIdentifier(this.$route.params.identifier)
            .then(x => {
                this.portafogli = x;
            })
        });
    }

}
