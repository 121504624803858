




































































































import { Component, Vue } from 'vue-property-decorator';
import * as OM from '@/Model';
import * as VM from '@/viewModel';
import toastr from 'toastr';
import { ArchivioClient, ChallengeClient, TemplateClient } from '@/services/Services';

@Component
export default class AssegnazionePremiModal extends Vue {

    challenges: OM.ValueIdentifierOfString[] = [];
    vm: OM.AssegnaPremiVm = new OM.AssegnaPremiVm();
    templates: OM.ValueIdentifierOfString[] = [];
    
    nodes: OM.ValueIdentifierOfString[] = [];
    pathToAdd: OM.ValueIdentifierOfString = null

    created(){
        TemplateClient.getNameIdentifier()
        .then(x => this.templates = x)

        ChallengeClient.getNameIdentifier()
        .then(x => this.challenges = x)

        ArchivioClient.getNamesByParent(null)
        .then(x => this.nodes = x);
    }

    pushPath(){
        this.vm.nodePath.push(this.pathToAdd);
        ArchivioClient.getNamesByParent(this.pathToAdd.identifier)
        .then(x => this.nodes = x);
        this.pathToAdd = null;
    }
    clearPath(){
        this.vm.nodePath = [];
        ArchivioClient.getNamesByParent(null)
        .then(x => this.nodes = x);
    }

    get selectedChallenge(){
        return {
            identifier: this.vm.challengeIdentifier,
            value: this.vm.challengeName
        };
    }
    set selectedChallenge(val: OM.ValueIdentifierOfString){
        this.vm.challengeIdentifier = val.identifier;
        this.vm.challengeName = val.value;
    }

    assegnaPremi(){
        ChallengeClient.assegnaPremi(this.vm)
        .then(x => {
            toastr.success('Ok');
        })
        .catch(err => {
            console.log(err);
            toastr.error("Errore");
        });
    }

}
