





































import { Component, Vue } from 'vue-property-decorator';
import * as OM from '@/Model';
import ChallengeModal from './challengeModal.vue'
import { ChallengeClient } from '@/services/Services';

@Component
export default class ChallengeListView extends Vue {

    challenges: OM.Challenge[] = [];

    created(){
        this.init();
    }

    init(){
        ChallengeClient.getAll()
        .then(x => {
            this.challenges = x;
        })
    }

    openModal(item: OM.Challenge){
        this.$opModal.show(ChallengeModal, {
            item: item
        }, () => {
            this.init();
        })
    }

    elimina(item: OM.Challenge){
        if(!confirm("Sei sicuro?"))
            return;
        ChallengeClient.delete(item.identifier)
        .then(x => {
            this.init();
        })
    }

}
