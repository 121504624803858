









import { Component, Vue } from 'vue-property-decorator';
import * as OM from '@/Model';
import MovimentiTable from './movimentiTable.vue';
import { MovimentoClient, PortafoglioClient } from '@/services/Services';

@Component({
    components: {
        MovimentiTable
    }
})
export default class MovimentiView extends Vue {

    movimenti: OM.Movimento[] = []
    userBalance: OM.Portafoglio = null;

    created(){
        let proms = [];
        proms.push(MovimentoClient.getByUserBalanceIdentifier(this.$route.params.portafoglioIdentifier));
        proms.push(PortafoglioClient.getById(this.$route.params.portafoglioIdentifier));
        Promise.all(proms)
        .then(x => {
            this.movimenti = x[0];
            this.userBalance = x[1];
        })
    }

}
