import { render, staticRenderFns } from "./templateModal.vue?vue&type=template&id=dcdb4cae&scoped=true&"
import script from "./templateModal.vue?vue&type=script&lang=ts&"
export * from "./templateModal.vue?vue&type=script&lang=ts&"
import style0 from "./templateModal.vue?vue&type=style&index=0&id=dcdb4cae&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dcdb4cae",
  null
  
)

export default component.exports