import { render, staticRenderFns } from "./domandeQualitaConfig.vue?vue&type=template&id=12c5161a&"
import script from "./domandeQualitaConfig.vue?vue&type=script&lang=ts&"
export * from "./domandeQualitaConfig.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports