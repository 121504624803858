































import { Component, Vue } from 'vue-property-decorator';
import { routes } from '@/router';
import * as OM from '@/Model';
import DomandaModal from './domandaModal.vue';
import { DomandaQualitaConfigClient } from '@/services/Services';

@Component
export default class DomandeQualitaConfigView extends Vue {

    domandeQualita: OM.DomandaQualitaConfig[] = [];

    created(){
        DomandaQualitaConfigClient.getAll()
        .then(x => {
            this.domandeQualita = x;
        })
    }

    addQuestion() {
        DomandaQualitaConfigClient.createDomandaQualitaConfig()
        .then(x => {
            this.domandeQualita.push(x);
        })
    }
    
    deleteQuestion(item: OM.DomandaQualitaConfig, index) {
        DomandaQualitaConfigClient.deleteDomandaQualitaConfig(item.identifier)
        .then(x => {
            this.domandeQualita.splice(index, 1);
        })
    }

    openDomandaModal(domanda){
        this.$opModal.show(DomandaModal, {
            item: domanda
        })
    }

}
