















































































import { Component, Vue } from 'vue-property-decorator';
import * as OM from '@/Model';
import * as VM from '@/viewModel';
import AssetInPortafoglioTable from './assetInPortafoglioTable.vue';
import DateValueChart from '@/components/dateValueChart.vue';
import DateValueTable from '@/components/dateValueTable.vue';
import store from '@/store';
import { AssetInPortafoglioClient, PortafoglioClient } from '@/services/Services';

@Component({
    components: {
        AssetInPortafoglioTable,
        DateValueChart,
        DateValueTable
    }
})
export default class PortafoglioView extends Vue {

    assets: OM.AssetInPortafoglioVm[] = [];
    resoconto: OM.ResocontoPortafoglio = new OM.ResocontoPortafoglio();
    selectedAsset: OM.KeyValuePairOfStringAndResocontoPortafoglio = null;

    filterVm: OM.AssetFilterVm = new OM.AssetFilterVm();
    classes: string[] = [];

    hides: boolean[] = [];
    selectedTab: string = "";

    created(){
        this.init();
        store.state.dateAdvancedCallbacks.push((x => {
            this.init();
        }));
    }
    
    init(){
        this.filterVm.portafoglioIdentifier = this.$route.params.portafoglioIdentifier;
        AssetInPortafoglioClient.getPortafoglioByFilter(this.filterVm)
        .then(x => {
            this.assets = x;
        })
        PortafoglioClient.getResocontoPortafoglio(this.filterVm)
        .then(x => {
            this.resoconto = x;
        })
        PortafoglioClient.getPortafoglioAssetClasses(this.filterVm)
        .then(x => {
            this.classes = x;
        });
    }

    selectAsset(item: OM.KeyValuePairOfStringAndResocontoPortafoglio){
        this.selectedAsset = item;
    }

    addToFilter(value){
        if(this.filterVm.assetType == '')
            this.filterVm.assetType = value;
        else if(this.filterVm.macroClass == '')
            this.filterVm.macroClass = value;
        else if(this.filterVm.microClass == '')
            this.filterVm.microClass = value;
        else
            return;

        this.init();
        // PortafoglioClient.getPortafoglioAssetClasses(this.filterVm)
        // .then(x => {
        //     this.classes = x;
        // });
        // PortafoglioClient.getResocontoPortafoglio(this.filterVm)
        // .then(x => {
        //     this.resoconto = x;
        // })
    }
    
    removeLastFilter(){
        if(this.filterVm.microClass != '')
            this.filterVm.microClass = '';
        else if(this.filterVm.macroClass != '')
            this.filterVm.macroClass = '';
        else if(this.filterVm.assetType != '')
            this.filterVm.assetType = '';
        else
            return;

        this.init();
        // PortafoglioClient.getPortafoglioAssetClasses(this.filterVm)
        // .then(x => {
        //     this.classes = x;
        // });
        // PortafoglioClient.getResocontoPortafoglio(this.filterVm)
        // .then(x => {
        //     this.resoconto = x;
        // })
    }

    setHide(val, pos){
        Vue.set(this.hides, pos, val);
    }

}
