



























import { Component, Vue, Watch } from 'vue-property-decorator';
import * as OM from '@/Model';
import * as VM from '@/viewModel';
import Chat from '@/components/chat.vue';
import ChatConfigModal from './chatConfigModal.vue';
import store from '@/store';
import { ChatClient } from '@/services/Services';

@Component({
    components: {
        Chat
    }
})
export default class ChatRooms extends Vue {

    chatFilter: string = "";
    allChatRooms: OM.ChatRoomVm[] = [];
    chatIdentifier: string = "";

    filteredChatRooms: OM.ChatRoomVm[] = [];
    
    filterChatRooms(){
        if(this.chatFilter){
            let filter = this.chatFilter.toLowerCase().replace(' ', '');
            this.filteredChatRooms = this.allChatRooms.filter(x => x.userName.toLowerCase().replace(' ', '').indexOf(filter) > -1);
        } else {
            this.filteredChatRooms = this.allChatRooms;
        }
    }

    interval: any;
    created(){
        ChatClient.getChatRooms()
        .then(x => {
            this.allChatRooms = x;
            this.filteredChatRooms = this.allChatRooms;
            ChatClient.checkNewMessagesAllChats()
            .then(x => {
                store.state.counters.chat = x;
            })
            this.interval = setInterval(() => {
                ChatClient.checkNewMessagesAllChats()
                .then(x => {
                    store.state.counters.chat = x;
                })
            }, 10000);
        })
        store.watch(store.getters.getChatCounters, (counters) => {
            this.reorderChats(counters);
        })
    }

    destroyed(){
        clearInterval(this.interval);
    }

    reorderChats(counters){
        this.filteredChatRooms.sort(function(a, b){
            if(counters[a.chatIdentifier] == undefined)
                return 1;
            if(counters[b.chatIdentifier] == undefined)
                return -1;

            if(counters[a.chatIdentifier] < counters[b.chatIdentifier])
                return 1;
            else if(counters[a.chatIdentifier] > counters[b.chatIdentifier])
                return -1;
            else
                return 0;
        })
    }

    selectChat(item: OM.ChatRoomVm){
        store.state.counters.chat[item.chatIdentifier] = 0;
        this.chatIdentifier = "";
        this.$nextTick(() => {
            this.chatIdentifier = item.chatIdentifier;
        });
    }

    openConfigModal(){
        this.$opModal.show(ChatConfigModal, {})
    }


}
