export function stdDev(input: number[]){
    let media = average(input);
    let sumOfSquaresOfDifferences = 0;
    input.forEach(x => {
        sumOfSquaresOfDifferences += Math.pow(x - media, 2);
    });
    return Math.sqrt(sumOfSquaresOfDifferences / (input.length - 1));
}

export function average(input: number[]){
    let sum = 0;
    input.forEach(x => sum += x);
    return sum / input.length;
}