



























import { Component, Vue } from 'vue-property-decorator';
import * as OM from '@/Model';
import * as VM from '@/viewModel';
import toastr from 'toastr';
import AssegnazionePremiModal from './assegnazionePremiModal.vue';
import PremiTable from './premiTable.vue';
import { ChallengeClient } from '@/services/Services';
import OpTable from '@/components/opTable.vue'

@Component({
    components: {
        PremiTable,
        OpTable
    }
})
export default class Premi extends Vue {

    premi: OM.PremiUserGroupedVm[] = [];

    created(){
        ChallengeClient.getPremiByPeriods()
        .then(x => {
            this.premi = x;
        })
    }

    openAssegnazionePremi(){
        this.$opModal.show(AssegnazionePremiModal, {
        }, () => {
            ChallengeClient.getPremiByPeriods()
            .then(x => {
                this.premi = x;
            })
        });
    }

}
