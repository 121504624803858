







import { Component, Vue } from 'vue-property-decorator';
@Component
export default class AgLinkDetail extends Vue {
    getCurrentRoute(){
        return this.$route.path;
    }
}
