import Vue from 'vue';
import moment from 'moment';
import * as Enum from '@/enum';

Vue.filter('date', function(value: any, showTime: boolean) {
    if (value) {
        if(showTime)
            return moment(value).format('DD-MM-YYYY HH:mm:ss');
        else
            return moment(value).format('DD-MM-YYYY');
    }
})

Vue.filter('%', function(value: any, decimals: number) {
    if (value) {
        return (value * 100).toFixed(decimals);
    }
})

Vue.filter('currency', function(value: any, decimals: number) {
    if(!decimals && decimals != 0)
        decimals = 2;
    if (value || value == 0) {
        let val = (value/1).toFixed(decimals).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    }
})

let tipiOrdine = Enum.EnumToList(Enum.TipoOrdine)
Vue.filter('tipoOrdine', function(value: any) {
    if (value) {
        return tipiOrdine[value];
    }
})