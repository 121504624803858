


















import { Component, Vue, Prop } from 'vue-property-decorator';
import * as OM from '@/Model';
import * as VM from '@/viewModel';
import * as AgGrid from 'ag-grid-vue';
import AgLinkDetail from '@/components/tableComponents/agLinkDetail.vue'
import DateFilter from '@/components/tableComponents/dateFilter.vue'
import SelectItemCell from '@/components/tableComponents/selectItemCell.vue'

@Component({
    components: {
        AgGridVue: AgGrid.AgGridVue,
        AgLinkDetail
    }
})
export default class UsersTable extends Vue {
    @Prop({
        type: Array
    }) rowData: VM.UserVm[];

    columnDefs: any[] = [
        { headerName: 'Nome', field: 'name', cellRendererFramework: AgLinkDetail },
        { headerName: 'Cognome', field: 'surname'},
        { headerName: 'Nickname', field: 'alias' },
        { headerName: 'Email', field: 'email' },
        { headerName: 'Ultimo accesso', field: 'lastAccessDate', cellRendererFramework: DateFilter },
        { headerName: 'Seleziona', cellRendererFramework: SelectItemCell },
        { headerName: 'LastLoginOn', field: 'lastLoginOn', cellRendererFramework: DateFilter },
        { headerName: 'LastAppVersion', field: 'lastLoginVersion' }
    ];
    gridOptions: any = {
        context: {
            componentParent: this
        }
    };
    
    fullTextFilter: string = "";
    onFilterTextBoxChanged(){
        this.gridOptions.api.setQuickFilter(this.fullTextFilter);
    }
    mounted(){
        this.gridOptions.api.sizeColumnsToFit();
        window.addEventListener('resize', this.onResize);
    }
    onResize(){
        setTimeout(() => {
            this.gridOptions.api.sizeColumnsToFit();
        });
    }
    destroyed(){
        window.removeEventListener('resize', this.onResize);
    }
    
    selectItem(data){
        data.selected = !data.selected;
        this.$emit('selectItem', data);
    }

}
