export function EnumToList(inputEnum: any): { [key: string]: number | string } {
    let ris: any = {};
    for(var prop in inputEnum){
        if (typeof inputEnum[prop] === 'number') {
            ris[inputEnum[prop]] = prop.replace(/_/g, ' ');
        }
    }
    return ris;
}

export enum TipoAsset
{
    Benchmark = 1,
    Valuta,
    Azione,
    Obbligazione,
    Etf,
    Fondo
}

export enum TipoOrdine
{
    Acquisto = 1,
    Vendita,
    VenditaSwitch,
    AcquistoSwitch
}

export enum CompareOperator
{
    Equals,
    Different,
    MoreThan,
    LessThan,
    MoreThanOrEqual,
    LessThanOrEqual
}