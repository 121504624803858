import { render, staticRenderFns } from "./ordiniTable.vue?vue&type=template&id=dd4e664c&"
import script from "./ordiniTable.vue?vue&type=script&lang=ts&"
export * from "./ordiniTable.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports