











































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { StorageServices } from '@/services/StorageServices';
import * as VM from '@/viewModel';
import * as OM from '@/Model';
import * as Enum from '@/enum';
import WalletBar from '@/components/walletBar.vue';
import store from '@/store';
import { stdDev } from '@/utility'
import { OrdineClient, PortafoglioClient } from '@/services/Services';

@Component({
    components: {
        WalletBar
    }
})
export default class Vendi extends Vue {
    
    @Prop() userIdentifier: string;
    @Prop() portafoglioIdentifier: string;

    @Prop({
        type: [Object, Array],
        default: () => []
    }) assets: OM.AssetForDonutChart[];

    asset: OM.AssetForDonutChart = null;

    percValue: number = 0;
    maxInput: number = 100;
    dataForCalcolo: OM.DataForCalcoloAcquistoVm = new OM.DataForCalcoloAcquistoVm();
    walletRisk: number = 0;
    prezzoVendita: number = 0;
    isVenditaInput: boolean = false;
    venditaInput: number = 0;

    quantita: number = 0;
    dataOrdine: string = new Date().toISOString().split('T')[0];

    created(){
        PortafoglioClient.getPortafoglioStatus(this.portafoglioIdentifier)
        .then(x => {
            store.state.portafoglioStatus = x;
        })
    }

    getDataForCalcoloAcquisto(){
        if(!this.asset)
            return;
        PortafoglioClient.getDataForCalcoloAcquisto(this.portafoglioIdentifier, this.asset.assetIdentifier)
        .then(x => {
            this.prezzoVendita = x.lastNav;
            this.maxInput = 100;
            this.dataForCalcolo = x;
            this.calcolaWalletRisk(0);
        })
    }
    
    @Watch('venditaInput')
    onVenditaInputChange(next, prev){
        if(next > this.asset.controvalore)
            next = this.asset.controvalore;
        if(next < 0)
            next = 0;
        this.percValue = next * 100 / this.asset.controvalore;
    }
    
    showVenditaInput() {
        this.isVenditaInput = !this.isVenditaInput;
        this.venditaInput = parseFloat(this.totaleVendita.toFixed(2));
        if (this.venditaInput == 0)
            this.venditaInput = null;
        if(this.isVenditaInput){
            this.$nextTick(() => {
                (<HTMLElement>this.$refs.venditaInput).focus();
            })
        }
    }

    get totaleVendita(){
        if(!this.asset)
            return 0;
        return this.asset.controvalore * this.percValue / 100;
    }

    get quantitaVendita(){
        if(!this.asset)
            return 0;
        return this.asset.quantita * this.percValue / 100;
    }

    @Watch('percValue')
    onSliderValueChange(next, prev){
        this.calcolaWalletRisk(next);
    }

    calcolaWalletRisk(sliderValue){
        let variazioniConAsset = [];
        let percent = sliderValue / 100;
        this.dataForCalcolo.variazioniPesate.forEach((x, i) => {
            variazioniConAsset[i] = x + percent * this.dataForCalcolo.variazioniAsset[i];
        });
        this.walletRisk = stdDev(variazioniConAsset) * Math.sqrt(250);
    }

    creaOrdine(){
        let vm: OM.CreaOrdineVm = new OM.CreaOrdineVm();
        vm.userIdentifier = this.userIdentifier;
        vm.portafoglioIdentifier = this.portafoglioIdentifier;
        vm.tipoOrdine = Enum.TipoOrdine.Vendita;
        vm.assetIdentifier = this.asset.assetIdentifier;
        vm.controvalore = this.totaleVendita;
        vm.dataOrdine = new Date().toISOString();
        OrdineClient.creaOrdine(vm)
        .then(x => {
            PortafoglioClient.getPortafoglioStatus(this.portafoglioIdentifier)
            .then(x => {
                store.state.portafoglioStatus = x;
            });
            this.$emit('close');
        })
    }

}
