












import { Component, Vue } from 'vue-property-decorator';
import * as OM from '@/Model';
import OrdiniTable from './ordiniTable.vue';
import CreaOrdineModal from './creaOrdineModal.vue';
import store from '@/store';
import toastr from 'toastr';
import { OrdineClient, PortafoglioClient } from '@/services/Services';

@Component({
    components: {
        OrdiniTable
    }
})
export default class MovimentiView extends Vue {

    ordini: OM.Ordine[] = []
    userBalance: OM.Portafoglio = null;

    created(){
        this.init();
        store.state.dateAdvancedCallbacks.push((x => {
            this.init();
        }));
    }

    init(){
        let proms = [];
        proms.push(OrdineClient.getByUserBalanceIdentifier(this.$route.params.portafoglioIdentifier));
        proms.push(PortafoglioClient.getById(this.$route.params.portafoglioIdentifier));
        Promise.all(proms)
        .then(x => {
            this.ordini = x[0];
            this.userBalance = x[1];
        })
    }

    openCreaOrdineModal(){
        this.$opModal.show(CreaOrdineModal, {
            userIdentifier: this.$route.params.userIdentifier,
            portafoglioIdentifier: this.$route.params.portafoglioIdentifier,
        }, () => {
            let proms = [];
            proms.push(OrdineClient.getByUserBalanceIdentifier(this.$route.params.portafoglioIdentifier));
            proms.push(PortafoglioClient.getById(this.$route.params.portafoglioIdentifier));
            Promise.all(proms)
            .then(x => {
                this.ordini = x[0];
                this.userBalance = x[1];
            })
        });
    }

    removeItem(ordine: OM.Ordine){
        if(!confirm("Sicuro?"))
            return;
        OrdineClient.delete(ordine.identifier)
        .then(x => {
            toastr.success("Ordine eliminato");
            let ind = this.ordini.indexOf(ordine);
            this.ordini.splice(ind, 1);
        });
    }

}
