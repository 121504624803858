


















import { Component, Vue, Prop } from 'vue-property-decorator';
import * as OM from '@/Model';
import * as VM from '@/viewModel';
import * as AgGrid from 'ag-grid-vue';
import DateFilter from '@/components/tableComponents/dateFilter.vue'

@Component({
    components: {
        AgGridVue: AgGrid.AgGridVue,
    }
})
export default class MovimentiTable extends Vue {
    @Prop({
        type: Array
    }) rowData: OM.Movimento[];

    columnDefs: any[] = [
        { headerName: 'Data', field: 'createdOn', cellRendererFramework: DateFilter },
        { headerName: 'Tipo movimento', field: 'tipoMovimento'},
        { headerName: 'Data valuta', field: 'dataMovimento', cellRendererFramework: DateFilter },
        { headerName: 'Codice bloomberg', field: 'idExcelStrumento' },
        { headerName: 'Quantita', field: 'quantita' },
        { headerName: 'Prezzo', field: 'prezzo' },
        { headerName: 'Valuta', field: 'valuta', width: 120 },
        { headerName: 'Commissioni', field: 'commissioni', width: 80 },
        { headerName: 'Processato', field: 'processato', width: 80 },
    ];
        
    gridOptions: any = {
        context: {
            componentParent: this
        }
    };
    
    fullTextFilter: string = "";
    onFilterTextBoxChanged(){
        this.gridOptions.api.setQuickFilter(this.fullTextFilter);
    }
    mounted(){
        this.gridOptions.api.sizeColumnsToFit();
        window.addEventListener('resize', this.onResize);
    }
    onResize(){
        setTimeout(() => {
            this.gridOptions.api.sizeColumnsToFit();
        });
    }
    destroyed(){
        window.removeEventListener('resize', this.onResize);
    }

}
