


















import { Component, Vue } from 'vue-property-decorator';
import * as OM from '@/Model';
import * as VM from '@/viewModel';
import GlossarioModal from './glossarioModal.vue';
import { GlossarioClient } from '@/services/Services';

@Component
export default class GlossarioView extends Vue {

    groups: OM.GlossarioGroupVm[] = [];
    created(){
        this.init();
    }

    init(){
        GlossarioClient.getAll()
        .then(x => {
            this.groups = x;
        })
    }

    openModal(item: OM.GlossarioVm){
        let prom = new Promise((resolve, reject) => {
            if(item){
                GlossarioClient.getById(item.identifier)
                .then(x => {
                    resolve(x);
                })
            } else {
                resolve(null);
            }
        })
        prom.then(data => {
            this.$opModal.show(GlossarioModal, {
                item: data
            }, () => {
                this.init();
            })
        })
    }

}
