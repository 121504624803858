














































































import { Component, Vue, Prop } from 'vue-property-decorator';
import * as VM from '@/viewModel';
import * as OM from '@/Model';
import * as Enum from '@/enum';

@Component
export default class TemplateModal extends Vue {
    
    @Prop({
        type: [Object, OM.Template]
    }) template: OM.Template;
    
    @Prop({
        type: [Array]
    }) contentVariables: string[];

    @Prop({
        required: true
    }) callback: any;

    backup: OM.Template = null;
    newBlock: OM.LogicBlockDefinition = null;

    compareOperators: any = null;
    created(){
        this.compareOperators = Enum.EnumToList(Enum.CompareOperator);
        this.backup = { ...this.template };
    }

    insertVariable(val: string){
        this.insertInTextArea("{{" + val + "}}");
    }
    insertLogicBlock(blockIndex: number){
        this.insertInTextArea('{{#' + 'LogicBlocks.' + blockIndex +'}} {{/LogicBlocks.' + blockIndex + '}}');
    }
    insertInTextArea(toInsert: string){
        // obtain the object reference for the textarea>
        var txtarea: any = document.getElementById("textaria");
        var start = txtarea.selectionStart;
        var finish = txtarea.selectionEnd;
        //obtain all Text
        var allText = txtarea.value;
        // obtain the selected text
        var sel = allText.substring(start, finish);
        var newText = allText.substring(0, start) + toInsert + allText.substring(finish, allText.length);
        this.template.htmlText = newText;
    }

    addLogicBlock(){
        this.newBlock = {
            propertyA: '',
            propertyB: '',
            valueA: '',
            valueB: '',
            compareOperator: null
        }
    }
    confirmNewBlock(){
        this.template.logicBlockDefinitions.push({...this.newBlock});
        this.newBlock = null;
    }

    annulla(){
        for(let prop in this.backup){
            this.template[prop] = this.backup[prop];
        }
        this.$emit('close');
    }

    save(){
        this.callback(this.template);
        this.$emit('close');
    }

}
