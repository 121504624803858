










































import { Component, Vue, Prop } from 'vue-property-decorator';
import * as OM from '@/Model';
import { GlossarioClient } from '@/services/Services';

@Component
export default class SuccessModal extends Vue {
    
    @Prop({
        type: [Object, OM.Glossario],
        default: null
    }) item: OM.Glossario;

    localItem: OM.Glossario = new OM.Glossario();
    
    created(){
        if(this.item){
            this.localItem = this.item;
        }
    }

    save(){
        GlossarioClient.save(this.localItem)
        .then(x => {
            this.$emit('close');
        });
    }

}
