



































import { Component, Vue } from 'vue-property-decorator';
import * as OM from '@/Model';
import * as VM from '@/viewModel';
import { RispostaQualitaClient } from '@/services/Services';

@Component({
})
export default class RisposteQualitaView extends Vue {

    risposte: OM.RispostaQualita[] = [];

    created(){
        RispostaQualitaClient.getAll()
        .then(x => {
            this.risposte = x;
        })
    }
}
