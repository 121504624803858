

















































import { Component, Vue } from 'vue-property-decorator';
import * as OM from '@/Model';
import * as VM from '@/viewModel';
import LezioniListTable from './lezioniListTable.vue';
import { LezioneClient } from '@/services/Services';
import { CaricamentoFileServices } from '@/services/CaricamentoFileServices';
import FileSelect from '@/components/fileSelect.vue'

@Component({
    components: {
        LezioniListTable,
        FileSelect
    }
})
export default class LezioniView extends Vue {

    lezioni: OM.Lezione[] = [];

    created(){
        LezioneClient.getAllLezioni()
        .then(x => {
            this.lezioni = x;
        })
    }

    update(lezione: OM.Lezione){
        LezioneClient.save(lezione)
        .then(x => {
        })
    }

    uploadAudio(file, lezione: OM.Lezione){
        if(!confirm("Vuoi caricare questo file?"))
            return;
            
        CaricamentoFileServices.UploadLezioneAudio({
            lezioneIdentifier: lezione.identifier, 
            file: file
        })
        .then(x => {
            lezione.pollyUrl = x;
        })
    }

    togglePolly(item: OM.Lezione){
        item.usePolly = !item.usePolly;
        this.update(item);
    }

}
