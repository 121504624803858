import Vue from 'vue';
import Router from 'vue-router';
import Dizionario from '@/views/config/dizionario/dizionario.vue';
import DizionarioHtml from '@/views/config/dizionarioHtml/dizionarioHtml.vue';
import Config from '@/views/config/config.vue';
import QuestionarioIniziale from '@/views/config/questionarioIniziale/questionarioIniziale.vue';
import Login from '@/views/login/login.vue';
import Layout from '@/views/layout/layout.vue';
import Cinture from '@/views/cinture/cinture.vue';
import EditCintura from '@/views/cinture/editCintura.vue';
import Lezioni from '@/views/lezioni/lezioni.vue';
import LezioniList from '@/views/lezioni/lezioniList.vue';
import EditLezione from '@/views/lezioni/editLezione.vue';
import Glossario from '@/views/glossario/glossario.vue';
import GalleriaImmagini from '@/views/galleriaImmagini/galleriaImmagini.vue';
import CaricamentoFile from '@/views/caricamentoFile/caricamentoFile.vue';
import AreaTest from '@/views/areaTest/areaTest.vue';
import Assets from '@/views/assets/assets.vue';
import AssetDetail from '@/views/assets/assetDetail.vue';
import Users from '@/views/users/users.vue';
import UserDetail from '@/views/users/userDetail.vue';
import Movimenti from '@/views/movimenti/movimenti.vue';
import Ordini from '@/views/ordini/ordini.vue';
import Portafoglio from '@/views/portafoglio/portafoglio.vue';
import ChallengeList from '@/views/challenges/challengeList.vue';
import DomandeQualitaConfig from '@/views/domandeQualitaConfig/domandeQualitaConfig.vue';
import RisposteQualita from '@/views/domandeQualitaConfig/risposteQualita.vue';
import ChatRooms from '@/views/chat/chatRooms.vue';
import Archivio from '@/views/archivio/archivio.vue';
import Premi from '@/views/premi/premi.vue';
import Templates from '@/views/templates/templates.vue';
import store from './store';

Vue.use(Router);
export let routes = [
    { label: 'Configurazione', icon: 'fas fa-cog', path: '', children:
        [
            { label: 'Dizionario', icon: 'fas fa-book-open', path: '/app/dizionario' },
            { label: 'DizionarioHTML', icon: 'fas fa-code', path: '/app/dizionarioHtml' },
            { label: 'Questionario Iniziale', icon: 'fas fa-book', path: '/app/questionarioIniziale' },
            { label: 'Generici', icon: 'fas fa-cog', path: '/app/config' },
            { label: 'Templates', icon: 'fas fa-file', path: '/app/template' },
        ] 
    },
    { label: 'Configura cinture', icon: 'fas fa-stream', path: '/app/cinture', children: [
            { label: 'Lista Lezioni', icon: 'fas fa-stream', path: '/app/lezioniList' },
        ]
    },
    { label: 'Archivio', icon: 'fas fa-archive', path: '/app/archivio' },
    { label: 'Glossario', icon: 'fas fa-book', path: '/app/glossario' },
    { label: 'Gare', icon: 'fas fa-trophy', path: '/app/challengeList' },
    { label: 'Domande qualità', icon: 'fas fa-question-circle', path: '/app/domandeQualitaConfig', children: 
        [
            { label: 'Configurazione', icon: 'fas fa-question-circle', path: '/app/domandeQualitaConfig' } ,
            { label: 'Risposte', icon: 'fas fa-list', path: '/app/risposteQualita' }
        ] 
    },
    { label: 'Utenti', icon: 'fas fa-users', path: '/app/users' },
    { label: 'Assets', icon: 'fas fa-chart-line', path: '/app/assets' },
    { label: 'Caricamento file', icon: 'fas fa-file-excel', path: '/app/caricamentoFile' },
    { label: 'Galleria Immagini', icon: 'fas fa-camera', path: '/app/galleriaImmagini' },
    { label: 'Laboratorio', icon: 'fas fa-vial', path: '/app/areaTest' },
    { label: 'Premi', icon: 'fas fa-trophy', path: '/app/premi' },
    { label: 'Chat', icon: 'fas fa-comments', path: '/app/chat' }
]

let _router = new Router({
    routes: [
        {
            path: '/',
            component: Login,
        },
        {
            path: '/app',
            component: Layout,
            children: [
                {
                    path: 'template',
                    component: Templates,
                },
                {
                    path: 'premi',
                    component: Premi,
                },
                {
                    path: 'archivio',
                    component: Archivio,
                },
                {
                    path: 'dizionario',
                    component: Dizionario,
                },
                {
                    path: 'config',
                    component: Config
                },
                {
                    path: 'dizionarioHtml',
                    component: DizionarioHtml,
                },
                {
                    path: 'questionarioIniziale',
                    component: QuestionarioIniziale,
                },
                {
                    path: 'cinture',
                    component: Cinture,
                },
                {
                    path: 'cinture/:identifier',
                    component: EditCintura,
                },
                {
                    path: 'lezioniList',
                    component: LezioniList,
                },
                {
                    path: 'lezioni/:temaIdentifier',
                    component: Lezioni,
                },
                {
                    path: 'lezioni/:temaIdentifier/:lezioneIdentifier',
                    component: EditLezione,
                },
                {
                    path: 'glossario',
                    component: Glossario,
                },
                {
                    path: 'galleriaImmagini',
                    component: GalleriaImmagini,
                },
                {
                    path: 'challengeList',
                    component: ChallengeList,
                },
                {
                    path: 'domandeQualitaConfig',
                    component: DomandeQualitaConfig,
                },
                {
                    path: 'risposteQualita',
                    component: RisposteQualita,
                },
                {
                    path: 'users',
                    component: Users,
                },
                {
                    path: 'users/:identifier',
                    component: UserDetail,
                },
                {
                    path: 'assets',
                    component: Assets,
                },
                {
                    path: 'assets/:identifier',
                    component: AssetDetail,
                },
                {
                    path: 'caricamentoFile',
                    component: CaricamentoFile,
                },
                {
                    path: 'portafoglio/:userIdentifier/:portafoglioIdentifier',
                    component: Portafoglio,
                },
                {
                    path: 'movimenti/:userIdentifier/:portafoglioIdentifier',
                    component: Movimenti,
                },
                {
                    path: 'ordini/:userIdentifier/:portafoglioIdentifier',
                    component: Ordini,
                },
                {
                    path: 'areaTest',
                    component: AreaTest,
                },
                {
                    path: 'chat',
                    component: ChatRooms,
                }
            ]
        }
    ]
});

_router.beforeEach((to, from, next) => {
    if(store.state.beforeNavCbs.length == 0)
        next();
    store.state.beforeNavCbs.forEach(x => {
        x(to, from, next);
    });
    store.state.beforeNavCbs = [];
})

export default _router;