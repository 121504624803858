





import { Component, Vue } from 'vue-property-decorator';

@Component
export default class SelectItemCell extends Vue {
    
    select(){
        let self: any = this;
        self.params.context.componentParent.selectItem(self.params.data);
    }

}
