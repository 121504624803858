


































import { Component, Vue, Prop } from 'vue-property-decorator';
import * as OM from '@/Model';
import * as VM from '@/viewModel';
import toastr from 'toastr';
import FileSelect from '@/components/fileSelect.vue';
import { ImageForLessonClient } from '@/services/Services';
import { CaricamentoFileServices } from '@/services/CaricamentoFileServices';

@Component({
    components: {
        FileSelect
    }
})
export default class UploadImmaginiModal extends Vue {
    
    imgFile: any = null;
    preview: any = "";

    upload(){
        CaricamentoFileServices.UploadImageForLesson({
            file: this.imgFile
        })
        .then(x => {
            toastr.success("Fatto");
            this.$emit('close');
        })
        .catch(err => {
            toastr.error("Errore");
        });
    }

    click(e,choice){
        (<HTMLElement>this.$refs.fileInput).click();
    }

    onChange(ev) {
        var file = ev.target.files[0];
        this.createFile(file);
      }

    dropImage(ev){
        ev.stopPropagation();
        ev.preventDefault();
        var file = ev.dataTransfer.files[0];
        this.createFile(file);
    }

    createFile(file) {
        if (!file.type.match('image.*')) {
            alert('Select an image');
            return;
        }
        var img = new Image();
        var reader = new FileReader();
        var vm = this;
        reader.onload = (e:any) => {
            this.preview = e.target.result;
        }
        reader.readAsDataURL(file);
        this.imgFile = file;
    }

}
