



































































































import { Component, Vue, Prop } from 'vue-property-decorator';
import * as OM from '@/Model';
import { AssetClient, ChallengeClient } from '@/services/Services';

@Component
export default class ChallengeModal extends Vue {
    
    @Prop({
        type: [Object, OM.Challenge],
        default: null
    }) item: OM.Challenge;

    localItem: OM.Challenge = new OM.Challenge();
    defaultIdToLoad: number = null;
    assetIds: number[] = [];
    assetsForChallengeList: OM.AssetVmForChallengeList[] = [];
    
    created(){
        if(this.item){
            this.localItem = this.item;
            var anno = (new Date(this.localItem.dataInizio).getFullYear()).toString();
            var mese = (new Date(this.localItem.dataInizio).getMonth() + 1).toString();
            if(mese.length == 1)
                mese = "0" + mese;
            var giorno = (new Date(this.localItem.dataInizio).getDate()).toString();
            if(giorno.length == 1)
                giorno = "0" + giorno;
            this.localItem.dataInizio = anno + "-" + mese + "-" + giorno;

            anno = (new Date(this.localItem.dataFine).getFullYear()).toString();
            mese = (new Date(this.localItem.dataFine).getMonth() + 1).toString();
            if(mese.length == 1)
                mese = "0" + mese;
            giorno = (new Date(this.localItem.dataFine).getDate()).toString();
            if(giorno.length == 1)
                giorno = "0" + giorno;
            this.localItem.dataFine = anno + "-" + mese + "-" + giorno;
        }
        AssetClient.getIdExcelList()
        .then(x => {
            this.assetIds = x;
        })
        AssetClient.getAssetVmForChallengeList()
        .then(x => {
            this.assetsForChallengeList = x;
        })
    }

    save(){
        ChallengeClient.save(this.localItem)
        .then(x => {
            this.$emit('close');
        });
    }

    addDefaultId(){
        if(!this.defaultIdToLoad)
            return;
        let toPush = new OM.DefaultIdExcelForChallenge();
        toPush.idExcel = this.defaultIdToLoad;
        toPush.percentuale = null;
        
        this.localItem.defaultIdExcels.push(toPush);
        this.defaultIdToLoad = null;
    }

    removeDefaultId(index){
        this.localItem.defaultIdExcels.splice(index, 1);
    }

    selectPage(items: OM.AssetVmForChallengeList[]){
        items.forEach(x => {
            let ind = this.localItem.allowedAssets.indexOf(x.idExcel);
            if(ind == -1){
                this.localItem.allowedAssets.push(x.idExcel);
            }
        })
    }

    unselectPage(items: OM.AssetVmForChallengeList[]){
        items.forEach(x => {
            let ind = this.localItem.allowedAssets.indexOf(x.idExcel);
            if(ind != -1){
                this.localItem.allowedAssets.splice(ind, 1);
            }
        })
    }

}
