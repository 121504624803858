
















































import { StorageServices } from '@/services/StorageServices';
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';

@Component
export default class OpTable extends Vue {
    @Prop() items: any[];
    @Prop({
        default: true
    }) showUpperPagination: boolean;
    @Prop({
        default: true
    }) showLowerPagination: boolean;
    @Prop() opName: string;
    @Prop({
        type: Number,
        default: 10
    }) itemsPerPage: number;
    @Prop() clearFilters: number;
    @Prop({ default: () => { return {} } }) filtersFn: any;

    tableRoot: any;
    allItems: any[];
    data: any[] = [];

    filters: ((item: any) => boolean)[] = [];
    sorts: SortDefinition[] = [];

    totalPages: number = 0;
    lowerPages: number = 0;
    upperPages: number = 0;
    pages: number[] = [];
    selectedPage: number = 1;


    setSelectedPage(index: number){
        this.selectedPage = index;
        this.calcPagination();
    }
    subtractPage(){
        if(this.selectedPage > 1){
            this.selectedPage--;
            this.calcPagination();
        }
    }
    addPage(){
        if(this.selectedPage < this.totalPages){
            this.selectedPage++;
            this.calcPagination();
        }
    }

    created(){
        this.allItems = this.items.slice();
        this.data = this.allItems.slice();
    }

    @Watch('items')
    onItemsChange(next, prev){
        this.allItems = this.items.slice();
        this.data = this.allItems.slice();
        this.applyFilters();
        this.calcPagination();
    }

    mounted(){
        var rootElement: any = this.$refs.rootElement;
        var classes = [];
        rootElement.classList.forEach(x => {
            classes.push(x);
        })
        rootElement.classList.value = "";
        this.tableRoot = this.$refs.tableRoot;
        classes.forEach(x => this.tableRoot.classList.add(x));
        
        let headTds = this.tableRoot.querySelectorAll('thead td')
        headTds.forEach(x => {
            this.insertFilter(x);
            this.insertSort(x);
        })

        let opFilters = StorageServices.getOpTableFilters(this.opName);
        for(let p in opFilters){
            let filterInput = this.filterInputs.find(x => x.name == p);
            if(filterInput){
                filterInput.value = opFilters[p];
            }
        }
        this.calcPagination();
    }

    @Watch('itemsPerPage')
    onItemsPerPageChange(next, prev){
        this.calcPagination();
    }

    calcPagination(){
        this.totalPages = Math.ceil((this.data.length) / this.itemsPerPage);
        if(this.selectedPage > this.totalPages && this.selectedPage > 1){
            this.selectedPage = this.totalPages;
        }
        this.lowerPages = this.selectedPage - 2;
        this.upperPages = this.selectedPage + 2;

        if(this.selectedPage == 1)
            this.upperPages += 2;
        else if(this.selectedPage == 2)
            this.upperPages += 1;

        if(this.selectedPage == this.totalPages)
            this.lowerPages -= 2;
        else if(this.selectedPage == this.totalPages - 1)
            this.lowerPages -= 1;

        if(this.upperPages > this.totalPages)
            this.upperPages = this.totalPages;
        if(this.lowerPages <= 0)
            this.lowerPages = 1;
            
        this.pages = [];
        for(let i = this.lowerPages; i <= this.upperPages; i++){
            this.pages.push(i);
        }
    }
    get paginated(){
        let start = this.itemsPerPage * (this.selectedPage - 1);
        return this.data.slice(start, start + this.itemsPerPage);
    }

    insertSort(element){
        if(!element.hasAttribute('sort'))
            return;

        let prop = element.getAttribute('sort');
        let sortDefinition = new SortDefinition();
        sortDefinition.type = "";
        sortDefinition.el = element;
        element.onclick = () => {
            if(!sortDefinition.type){
                sortDefinition.type = 'asc';
                element.classList.add('asc');
            } else if(sortDefinition.type == 'asc') {
                sortDefinition.type = 'desc';
                element.classList.remove('asc');
                element.classList.add('desc');
            } else {
                sortDefinition.type = '';
                element.classList.remove('asc');
                element.classList.remove('desc');
            }
            this.applySorts(sortDefinition);
        }
        sortDefinition.fn = (a, b) => {
            let aValue = this.getValueByProp(a, prop);
            let bValue = this.getValueByProp(b, prop);

            if(!aValue) aValue = -1;
            if(!bValue) bValue = -1;
            let aVal = aValue; //.toString().toLowerCase().trim();
            let bVal = bValue; //.toString().toLowerCase().trim();
            if (aVal < bVal)
                return sortDefinition.type == 'asc' ? -1 : 1;
            if (aVal > bVal)
                return sortDefinition.type == 'asc' ? 1 : -1;
            return 0;
        }
        this.sorts.push(sortDefinition);
    }

    filterInputs: HTMLInputElement[] = [];
    insertFilter(element){
        if(!element.hasAttribute('filter'))
            return;

        var inputContainer = document.createElement('div');
        var input = document.createElement('input');
        input.type = "text";
        let filterType = element.getAttribute('filterType');
        if(filterType){
            input.type = filterType;
            if(input.type == "checkbox"){
                input.value = "true";
            }
        }
        let prop = element.getAttribute('filter');
        let filterFn = (item) => {
            let val = input.value.toString().toLowerCase().trim();
            if(input.type == "checkbox")
                val = <any>!!input.checked;
            if(!val) return true;
            let propVal = this.getValueByProp(item, prop);
            if(!propVal && propVal !== false) return false;
            return propVal.toString().toLowerCase().trim().indexOf(val) > -1
        }
        if(this.filtersFn[prop]){
            filterFn = (item) => {
                let val = input.value.toString().toLowerCase().trim();
                if(input.type == "checkbox")
                    val = <any>!!input.checked;
                if(!val) return true;
                let propVal = this.getValueByProp(item, prop);
                if(!propVal && propVal !== false) return false;
                return this.filtersFn[prop](propVal, val);
            }
        }

        this.filters.push(filterFn);
        input.oninput = this.applyFilters;
        input.onclick = (ev) => ev.stopPropagation();
        inputContainer.classList.add('opTdFilterContainer')
        input.classList.add('opTdFilter')
        input.name = prop;
        this.filterInputs.push(input);
        element.append(inputContainer);
        inputContainer.append(input);
    }

    @Watch('clearFilters')
    onClearFilters(next, prev){
        this.filterInputs.forEach(x => x.value = "");
        this.applyFilters();
    }

    getValueByProp(input: any, propString: string){
        let props = propString.split('.');
        let ris = input;
        props.forEach(x => {
            if(ris != null)
                ris = ris[x];
        })
        return ris;
    }

    applyFilters(){
        this.data = this.allItems.slice();
        this.filters.forEach(x => {
            this.data = this.data.filter(x);
        })
        this.calcPagination();
    }

    applySorts(def: SortDefinition){
        if(this.sorts.filter(x => !x.type).length == this.sorts.length){
            this.applyFilters();
        } else {
            this.sorts.forEach(x => {
                if(x != def){
                    x.el.classList.remove('asc');
                    x.el.classList.remove('desc');
                    x.type = '';
                    return;
                }
                this.data.sort(x.fn);
            })
        }
    }

}

class SortDefinition {
    type: string;
    el: HTMLElement;
    fn: (a: any, b: any) => number;
}

