












































import { Component, Vue, Prop } from 'vue-property-decorator';
import * as OM from '@/Model';
import Accordion from '@/components/accordion.vue';
import { DomandaQualitaConfigClient } from '@/services/Services';

@Component({
    components: {
        Accordion
    }
})
export default class SuccessModal extends Vue {
    
    @Prop({
        type: [Object, OM.DomandaQualitaConfig],
        default: null
    }) item: OM.DomandaQualitaConfig;

    localItem: OM.DomandaQualitaConfig = new OM.DomandaQualitaConfig();
    rollback: any = null;
    created(){
        if(this.item){
            this.localItem = this.item;
        }
    }

    save(){
        DomandaQualitaConfigClient.save(this.localItem)
        .then(x => {
            this.$emit('close');
        });
    }

}
