
















import { Component, Vue } from 'vue-property-decorator';
import store from '@/store';

@Component
export default class App extends Vue {

    get showSpinner(){
        return store.state.showSpinner > 0;
    }

}
