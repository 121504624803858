







































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import * as OM from '@/Model';
import * as VM from '@/viewModel';
import * as Enum from '@/enum';
import WalletBar from '@/components/walletBar.vue';
import store from '@/store';
import { stdDev } from '@/utility'
import { OrdineClient, PortafoglioClient } from '@/services/Services';

@Component({
    components: {
        WalletBar
    }
})
export default class Acquista extends Vue {
    
    @Prop() userIdentifier: string;
    @Prop() portafoglioIdentifier: string;

    @Prop({
        type: [Object, Array],
        default: () => []
    }) assets: OM.AssetVm[];

    asset: OM.AssetVm = null;

    percValue: number = 0;
    maxInput: number = 100;
    dataForCalcolo: OM.DataForCalcoloAcquistoVm = new OM.DataForCalcoloAcquistoVm();
    walletRisk: number = 0;
    prezzoAcquisto: number = 0;
    isAcquistoInput: boolean = false;
    acquistoInput: number = 0;
    valoreInEur: number = 0;

    quantita: number = 0;
    dataOrdine: string = new Date().toISOString().split('T')[0];

    created(){
        PortafoglioClient.getPortafoglioStatusWithSwitch({
            portafoglioIdentifier: this.portafoglioIdentifier,
            creaSwitchVm: null
        })
        .then(x => {
            store.state.portafoglioStatus = x;
        })
    }

    getDataForCalcoloAcquisto(){
        if(!this.asset)
            return;
        PortafoglioClient.getDataForCalcoloAcquisto(this.portafoglioIdentifier, this.asset.identifier)
        .then(x => {
            this.valoreInEur = x.lastNav / store.state.valute[this.asset.valuta];
            this.prezzoAcquisto = x.lastNav;
            this.maxInput = store.state.portafoglioStatus.liquidityPercentage * 100;
            this.dataForCalcolo = x;
            this.calcolaWalletRisk(0);
        })
    }
    
    @Watch('acquistoInput')
    onAcquistoInputChange(next, prev){
        if(next > store.state.portafoglioStatus.liquidity)
            next = store.state.portafoglioStatus.liquidity;
        if(next < 0)
            next = 0;
        this.percValue = next * 100 / store.state.portafoglioStatus.liquidity;
    }
    
    showAcquistoInput() {
        this.isAcquistoInput = !this.isAcquistoInput;
        this.acquistoInput = parseFloat(this.totaleAcquisto.toFixed(2));
        if (this.acquistoInput == 0)
            this.acquistoInput = null;
        if(this.isAcquistoInput){
            this.$nextTick(() => {
                (<HTMLElement>this.$refs.acquistoInput).focus();
            })
        }
    }

    get totaleAcquisto(){
        let liqPerc = store.state.portafoglioStatus.liquidity / store.state.portafoglioStatus.liquidityPercentage;
        return liqPerc * this.percValue / 100;
    }

    get quantitaOrdine(){
        if (this.asset.tipoAsset.toLowerCase() == "fondo")
            return this.totaleAcquisto / this.valoreInEur;
        else if (this.asset.tipoAsset.toLowerCase() == "etf")
            return Math.round(this.totaleAcquisto / this.valoreInEur);
    }

    @Watch('percValue')
    onSliderValueChange(next, prev){
        this.calcolaWalletRisk(next);
    }

    calcolaWalletRisk(sliderValue){
        let variazioniConAsset = [];
        let percent = sliderValue / 100;
        this.dataForCalcolo.variazioniPesate.forEach((x, i) => {
            variazioniConAsset[i] = x + percent * this.dataForCalcolo.variazioniAsset[i];
        });
        this.walletRisk = stdDev(variazioniConAsset) * Math.sqrt(250);
    }

    creaOrdine(){
        let vm: OM.CreaOrdineVm = new OM.CreaOrdineVm();
        vm.userIdentifier = this.userIdentifier;
        vm.portafoglioIdentifier = this.portafoglioIdentifier;
        vm.tipoOrdine = Enum.TipoOrdine.Acquisto;
        vm.assetIdentifier = this.asset.identifier;
        vm.controvalore = this.totaleAcquisto;
        vm.dataOrdine = new Date().toISOString();
        OrdineClient.creaOrdine(vm)
        .then(x => {
            PortafoglioClient.getPortafoglioStatus(this.portafoglioIdentifier)
            .then(x => {
                store.state.portafoglioStatus = x;
            });
            this.$emit('close');
        })
    }

}
