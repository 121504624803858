









































































import { Component, Vue } from 'vue-property-decorator';
import * as OM from '@/Model';
import Chartist from 'chartist/dist/chartist.min.js';
import AssetChart from './assetChart.vue';
import DateValueTable from '@/components/dateValueTable.vue';
import { AssetClient } from '@/services/Services';

@Component({
    components: {
        AssetChart,
        DateValueTable
    }
})
export default class AssetDetailView extends Vue {

    asset: OM.Asset = null;
    chartist: any;

    created(){
        AssetClient.getById(this.$route.params.identifier)
        .then(x => {
            this.asset = x;
        })
    }
}
