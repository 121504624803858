

















import { Component, Vue, Prop } from 'vue-property-decorator';
import * as OM from '@/Model';
import * as VM from '@/viewModel';
import * as AgGrid from 'ag-grid-vue';
import PollyUrl from '@/components/tableComponents/pollyUrl.vue';
import AbilitaPolly from '@/components/tableComponents/abilitaPolly.vue';

@Component({
    components: {
        AgGridVue: AgGrid.AgGridVue
    }
})
export default class LezioniListTable extends Vue {
    @Prop({
        type: Array
    }) rowData: OM.Lezione[];

    get localData(){
        return this.rowData || [];
    }
    
    columnDefs: any[] = [
        { headerName: 'Titolo', field: 'titolo' },
        { headerName: 'Id Excel', field: 'idExcel'},
        { headerName: 'Tema', field: 'nomeTema' },
        { headerName: 'Cintura', field: 'nomeCintura' },
        { headerName: 'Revisione', field: 'revisione' },
        { headerName: 'Polly Url', field: 'pollyUrl', cellRendererFramework: PollyUrl },
        { headerName: 'Polly Abilitato', field: 'usePolly', cellRendererFramework: AbilitaPolly },
    ];
    gridOptions: any = {
        context: {
            componentParent: this
        }
    };
    
    fullTextFilter: string = "";
    onFilterTextBoxChanged(){
        this.gridOptions.api.setQuickFilter(this.fullTextFilter);
    }
    mounted(){
        this.gridOptions.api.sizeColumnsToFit();
        window.addEventListener('resize', this.onResize);
    }
    onResize(){
        setTimeout(() => {
            this.gridOptions.api.sizeColumnsToFit();
        });
    }
    destroyed(){
        window.removeEventListener('resize', this.onResize);
    }

    togglePolly(data: OM.Lezione){
        data.usePolly = !data.usePolly;
        this.$emit('update', data);
    }
    uploadAudio(data: OM.Lezione, file){
        this.$emit('uploadaudio', { data, file });
    }

}
