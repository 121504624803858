import Vue from 'vue'
import Vuex from 'vuex'
import * as VM from '@/viewModel'
import * as OM from '@/Model'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        counters: {
            chat: {}
        },
        loginData: new OM.LoginResponse(),
        showSpinner: 0,
        dateAdvancedCallbacks: [],
        beforeNavCbs: [],
        portafoglioStatus: null,
        valute: {
            EUR: 1,
            USD: 1,
            GBP: 1,
            CHF: 1,
            JPY: 1,
            EUREUR: 1,
            EURUSD: 1,
            EURGBP: 1,
            EURCHF: 1,
            EURJPY: 1
        }
    },
    mutations: {
        showSpinner(state){
            state.showSpinner++;
        },
        hideSpinner(state){
            state.showSpinner--;
            if(state.showSpinner < 0)
                state.showSpinner = 0;
        }
    },
    getters: {
        getChatCounters: state => () => state.counters.chat
    }
})
