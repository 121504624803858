











































import { Component, Vue, Prop } from 'vue-property-decorator';
import * as OM from '@/Model';
@Component
export default class QuestionarioInizialeOptions extends Vue {
    @Prop() list: OM.QuestionarioInizialeOption[];
    @Prop() label: string;

    get local(){
        return this.list ? this.list : [];
    }

    add(){
        let toAdd = new OM.QuestionarioInizialeOption();
        let tappaBuco = 1;
        this.local.forEach(x => {
            if(x.value <= tappaBuco)
                tappaBuco++;
        })
        toAdd.value = tappaBuco;
        toAdd.text = "";
        toAdd.enabled = true;
        toAdd.ordinamento = tappaBuco;
        this.local.push(toAdd);
    }

    remove(index){
        this.local.splice(index, 1);
    }
}

