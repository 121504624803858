































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { StorageServices } from '@/services/StorageServices';
import * as VM from '@/viewModel';
import * as OM from '@/Model';
import * as Enum from '@/enum';
import Acquista from './acquista.vue';
import Vendi from './vendi.vue';
import store from '@/store';
import { stdDev } from '@/utility'
import { AssetClient, PortafoglioClient } from '@/services/Services';

@Component({
    components: {
        Acquista,
        Vendi
    }
})
export default class CreaOrdineModal extends Vue {
    
    @Prop() userIdentifier: string;
    @Prop() portafoglioIdentifier: string;

    asset: OM.AssetVm = null;
    assets: OM.AssetVm[] = [];
    assetsForVendita: OM.AssetForDonutChart[] = [];

    percValue: number = 0;
    maxInput: number = 100;
    dataForCalcolo: OM.DataForCalcoloAcquistoVm = new OM.DataForCalcoloAcquistoVm();
    walletRisk: number = 0;
    valoreInEur: number = 0;
    isAcquistoInput: boolean = false;
    acquistoInput: number = 0;
    tipoOrdine: number = 0;

    quantita: number = 0;
    dataOrdine: string = new Date().toISOString().split('T')[0];

    setTipoOrdine(tipo){
        this.tipoOrdine = tipo;
        if(tipo == 1)
            this.getAllAssets()
        if(tipo == 2)
            this.getAssetsInPortafoglio()
    }
    getAllAssets(){
        AssetClient.getAll()
        .then(x => {
            this.assets = x;
        })
    }
    getAssetsInPortafoglio(){
        let filter = new OM.AssetFilterVm();
        filter.portafoglioIdentifier = this.$route.params.portafoglioIdentifier;
        PortafoglioClient.getDonutChart(filter)
        .then(x => {
            this.assetsForVendita = x;
        })
    }

}
