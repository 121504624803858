


















import { Component, Vue, Prop } from 'vue-property-decorator';
import * as OM from '@/Model';
import * as VM from '@/viewModel';
import * as AgGrid from 'ag-grid-vue';
import AgLinkDetail from '@/components/tableComponents/agLinkDetail.vue'

@Component({
    components: {
        AgGridVue: AgGrid.AgGridVue,
        AgLinkDetail
    }
})
export default class AssetsTable extends Vue {
    @Prop({
        type: Array
    }) rowData: OM.AssetVm[];

    columnDefs: any[] = [
        { headerName: 'Nome', field: 'nome', cellRendererFramework: AgLinkDetail },
        { headerName: 'Codice Bloomberg', field: 'codiceBloomberg'},
        { headerName: 'Isin', field: 'isin', width: 120 },
        { headerName: 'Tipo', field: 'tipo', width: 120 },
        { headerName: 'Micro Classe', field: 'microClass' },
        { headerName: 'Macro Classe', field: 'macroClass' },
        { headerName: 'Valuta', field: 'valuta', width: 120 },
        { headerName: 'Giorni Valuta buy', field: 'giorniValutaAcquisto', width: 80 },
        { headerName: 'Giorni Valuta sell', field: 'giorniValutaVendita', width: 80 },
    ];
    gridOptions: any = {
        context: {
            componentParent: this
        }
    };
    
    fullTextFilter: string = "";
    onFilterTextBoxChanged(){
        this.gridOptions.api.setQuickFilter(this.fullTextFilter);
    }
    mounted(){
        this.gridOptions.api.sizeColumnsToFit();
        window.addEventListener('resize', this.onResize);
    }
    onResize(){
        setTimeout(() => {
            this.gridOptions.api.sizeColumnsToFit();
        });
    }
    destroyed(){
        window.removeEventListener('resize', this.onResize);
    }

}
