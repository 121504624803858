

















import { Component, Vue, Prop } from 'vue-property-decorator';
import * as OM from '@/Model';
import * as AgGrid from 'ag-grid-vue';
import DateFilter from '@/components/tableComponents/dateFilter.vue'
import PercentFilter from '@/components/tableComponents/percentFilter.vue'

@Component({})
export default class DateValueTable extends Vue {
    @Prop({
        type: Array
    }) data: OM.DateValue[];

}
