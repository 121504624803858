



















import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import * as OM from '@/Model';
import * as VM from '@/viewModel';
import toastr from 'toastr';
import { ArchivioClient } from '@/services/Services';

@Component
export default class ArchivioNode extends Vue {

    get selected(){
        return this.selectionArray.indexOf(this.node.identifier) > -1;
    }
    set selected(val){
        if(val){
            this.selectionArray.push(this.node.identifier);
        } else {
            let index = this.selectionArray.indexOf(this.node.identifier);
            this.selectionArray.splice(index, 1);
        }
    }

    @Prop() selectionArray: string[];

    @Prop({
        type: [OM.ArchivioNodeVm, Object]
    }) node: OM.ArchivioNodeVm;

    emit(eventType: string){
        this.$emit(eventType, this.node)
    }

    setNodeOrder(){
        ArchivioClient.setNodeOrder(this.node.identifier, this.node.order)
        .then(x => {
            toastr.success("Ordine aggiornato");
        })
    }

}
