

































import { Component, Vue } from 'vue-property-decorator';
import * as OM from '@/Model';
import { CinturaClient } from '@/services/Services';

@Component
export default class CintureView extends Vue {

    list: OM.Cintura[] = [];

    created(){
        CinturaClient.getAll()
        .then(x => {
            this.list = x;
        })
    }

}
