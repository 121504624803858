































import { Component, Vue } from 'vue-property-decorator';
import * as OM from '@/Model';
import { LezioneClient, TemaClient } from '@/services/Services';

@Component
export default class LezioniView extends Vue {

    lezioni: OM.Lezione[] = [];
    tema: OM.Tema = new OM.Tema();

    created(){
        TemaClient.getById(this.$route.params.temaIdentifier)
        .then(x => {
            this.tema = x;
        })
        LezioneClient.getByTemaIdentifier(this.$route.params.temaIdentifier)
        .then(x => {
            this.lezioni = x;
        })
    }

}
