import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-bootstrap.css";
import 'chartist/dist/chartist.min.css';
import 'toastr/build/toastr.min.css';

import '@/filters';
import '@/directives';

import './css/style.css';
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { CommonServices } from '@/services/common';

CommonServices.showSpinnerCb = () => {
    store.state.showSpinner++;
};
CommonServices.hideSpinnerCb = () => {
    store.state.showSpinner--;
};

Vue.config.productionTip = false

import ModalPlugin from "@/Plugin/ModalPlugin"
Vue.use(ModalPlugin);

import OpTable from '@/components/opTable.vue';
Vue.component('opTable', OpTable);

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')


router.beforeEach((to, from, next) => {
    store.state.dateAdvancedCallbacks = [];
    next();
});

import toastr from 'toastr';
toastr.options = {
    closeButton: false,
    debug: false,
    newestOnTop: false,
    progressBar: true,
    positionClass: "toast-top-right",
    preventDuplicates: false,
    showDuration: 300,
    hideDuration: 1000,
    timeOut: 4000,
    extendedTimeOut: 1000,
    showEasing: "swing",
    hideEasing: "linear",
    showMethod: "fadeIn",
    hideMethod: "fadeOut"
};