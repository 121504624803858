























import { Component, Vue } from 'vue-property-decorator';
import * as OM from '@/Model';
import * as VM from '@/viewModel';
import ArchivioNode from './archivioNode.vue';
import NodeModal from './nodeModal.vue';
import toastr from 'toastr';
import { ArchivioClient, PushNotificationClient } from '@/services/Services';

@Component({
    components: {
        ArchivioNode
    }
})
export default class Archivio extends Vue {

    nodes: OM.ArchivioNodeVm[] = [];
    selectedUser: string = "";
    path: string = "";
    selectedNode: OM.ArchivioNodeVm = null;

    selectedNodes: string[] = [];

    deleteNodes(){
        let vm: OM.DeleteNodesVm = {
            nodes: this.selectedNodes
        }
        ArchivioClient.deleteNodes(vm)
        .then(x => {
            toastr.success("Eliminati");
            let nodeId = this.selectedNode ? this.selectedNode.identifier : null;
            ArchivioClient.getChildrenOf(nodeId, this.selectedUser)
            .then(c => {
                this.nodes = c;
                this.selectedNodes = [];
            })
        });
    }


    created(){
        this.changeNode(null);
    }
    
    navigate(item: OM.ArchivioNodeVm){
        this.changeNode(item);
    }

    goUp(){
        let proms = [];
        proms.push(ArchivioClient.getById(this.selectedNode.parentIdentifier));
        proms.push(ArchivioClient.getChildrenOf(this.selectedNode.parentIdentifier, null));
        Promise.all(proms)
        .then(xs => {
            if(xs[0]){
                this.selectedNode = {
                    parentIdentifier: xs[0].parentIdentifier,
                    identifier: xs[0].identifier,
                    isFolder: xs[0].isFolder,
                    name: xs[0].name,
                    iconUrl: xs[0].iconUrl,
                    isRead: false,
                    order: 0
                };
            } else {
                this.selectedNode = null;
            }
            this.nodes = xs[1];
            if(this.path){
                let splitted = this.path.split('/');
                splitted.pop();
                this.path = splitted.join('/');
            }
        })
    }

    changeNode(node: OM.ArchivioNodeVm){
        this.selectedNode = node;
        this.path += node ? '/' + node.name : '';
        let nodeIdentifier = node ? node.identifier : null;
        ArchivioClient.getChildrenOf(nodeIdentifier, this.selectedUser)
        .then(x => {
            this.nodes = x;
        })
    }

    edit(item: OM.ArchivioNodeVm){
        ArchivioClient.getById(item.identifier)
        .then(x => {
            let node: OM.EditNodeVm = {
                identifier: x.identifier,
                parentIdentifier: x.parentIdentifier,
                name: x.name,
                isFolder: x.isFolder,
                htmlContent: x.htmlContent,
                userIdentifier: x.userIdentifier,
                iconUrl: x.iconUrl,
                markToRead: false
            }
            this.openNodeModal(node);
        });
    }

    createNewNode(){
        let node: OM.EditNodeVm = {
            identifier: '',
            parentIdentifier: this.selectedNode ? this.selectedNode.identifier : null,
            name: '',
            isFolder: false,
            htmlContent: '',
            userIdentifier: this.selectedUser,
            iconUrl: '',
            markToRead: true
        };
        this.openNodeModal(node);
    }

    openNodeModal(item: OM.EditNodeVm){
        this.$opModal.show(NodeModal, {
            node: item,
            callback: (updatedNode: OM.EditNodeVm, pushConfig: OM.SendPushConfigVm) => {
                ArchivioClient.save(updatedNode)
                .then(x => {
                    let nodeId = this.selectedNode ? this.selectedNode.identifier : null;
                    ArchivioClient.getChildrenOf(nodeId, this.selectedUser)
                    .then(c => {
                        this.nodes = c;
                    })
                    if(pushConfig){
                        pushConfig.routeTo = "/archivio/" + x;
                        PushNotificationClient.sendToAll(pushConfig);
                    }
                })
            }
        })
    }

}
