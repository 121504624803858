















































import { Component, Vue } from 'vue-property-decorator';
import { routes } from '@/router';
import * as OM from '@/Model';
import toastr from 'toastr';
import { GlobalConfigClient } from '@/services/Services';

@Component
export default class DizionarioView extends Vue {

    config: OM.GlobalConfig = new OM.GlobalConfig();
    newKey: string = "";
    isNewKey: boolean = false;

    created(){
        GlobalConfigClient.getConfig()
        .then(x => {
            this.config = x;
            this.config.dizionario = this.config.dizionario || new OM.TimedDictionaryOfStringAndString();
        })
    }

    saveConfig(){
        this.config.dizionario.timestamp = Date.now();
        GlobalConfigClient.saveConfig(this.config)
        .then(x => {
            toastr.success("Salvato!");
            this.config = x;
        })
    }

    showNewKey(){
        this.isNewKey = true;
        this.newKey = "";
        this.$nextTick(() => {
            (<HTMLElement>this.$refs.__newKey).focus();
        });
    }

    revertNewKey(){
        this.isNewKey = false;
        this.newKey = "";
    }
    
    addNewKey(){
        for(let prop in this.config.dizionario.dictValues){
            if(this.newKey == prop){
                toastr.warning("Chiave già usata");
                return;
            }
        }
        this.isNewKey = false;
        Vue.set(this.config.dizionario.dictValues, this.newKey, "");
        this.$nextTick(() => {
            (<HTMLElement>this.$refs[this.newKey][0]).focus();
        });
    }

    removeKey(key: string){
        Vue.delete(this.config.dizionario.dictValues, key);
    }
}
