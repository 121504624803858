















import { Component, Vue } from 'vue-property-decorator';
import { routes } from '@/router';
import * as OM from '@/Model';
import toastr from 'toastr';
import { GlobalConfigClient } from '@/services/Services';

@Component
export default class DizionarioView extends Vue {

    config: OM.GlobalConfig = new OM.GlobalConfig();

    created(){
        GlobalConfigClient.getConfig()
        .then(x => {
            this.config = x;
        })
    }

    save(){
        GlobalConfigClient.saveConfig(this.config)
        .then(x => {
            toastr.success("Ok");
        })
    }

}
