























































import { Component, Vue } from 'vue-property-decorator';
import * as OM from '@/Model';
import * as VM from '@/viewModel';
import UsersTable from './usersTable.vue';
import SendPushModal from './sendPushModal.vue';
import { UserClient } from '@/services/Services';

@Component({
    components: {
        UsersTable
    }
})
export default class UsersView extends Vue {

    users: VM.UserVm[] = [];
    selectedUsers: string[] = [];

    created(){
        UserClient.getForTable()
        .then(x => {
            this.users = x;
            this.users.forEach(x => Vue.set(x, 'selected', false));
        })
    }

    selectAll(){
        if(this.selectedUsers.length > 0){
            this.selectedUsers = [];
            this.users.forEach(x => x.selected = false)
        } else {
            this.users.forEach(x => {
                x.selected = true;
                this.selectUser(x);
            })
        }
    }

    selectUser(item: VM.UserVm){
        item.selected = !item.selected;
        let ind = this.selectedUsers.indexOf(item.identifier);
        if(ind == -1)
            this.selectedUsers.push(item.identifier);
        else
            this.selectedUsers.splice(ind, 1);
    }

    openPushModal(){
        this.$opModal.show(SendPushModal, {
            userIds: this.selectedUsers
        })
    }

}
