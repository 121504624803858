




























































import { Component, Vue } from 'vue-property-decorator';
import * as OM from '@/Model';
import DomandaLezione from './domandaLezione.vue';
import Accordion from '@/components/accordion.vue'
import { LezioneClient } from '@/services/Services';

@Component({
    components: {
        DomandaLezione,
        Accordion
    }
})
export default class EditLezioneView extends Vue {
    lezione: OM.Lezione = new OM.Lezione();
    
    created(){
        this.lezione.temaIdentifier = this.$route.params.temaIdentifier;
        if(this.$route.params.lezioneIdentifier != "0"){
            LezioneClient.getById(this.$route.params.lezioneIdentifier)
            .then(x => {
                this.lezione = x;
            })
        }
    }

    save(){
        LezioneClient.save(this.lezione)
        .then(x => {
            this.lezione.identifier = x;
            if(this.$route.params.lezioneIdentifier == "0")
                this.$router.replace("/app/lezioni/" + this.$route.params.temaIdentifier + "/" + x);
        })
    }

    addDomanda(){
        this.lezione.questionario.domande.push(new OM.Domanda());
    }

}
