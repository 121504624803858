import Vue from 'vue';

Vue.directive('date', function(el, binding, next, prev){
    if(el.tagName.toLowerCase() != 'input'){
        console.error('v-date directive must be used on input tags. use type="date"');
        return;
    }
    if(el.getAttribute('type') != 'date'){
        console.error('v-date directive must be used on input with type="date"');
        return;
    }
    let modelDirectiveIndex = next.data.directives.findIndex(x => x.name == 'model');
    if(modelDirectiveIndex == -1){
        console.error('v-date directive requires v-model on element');
        return;
    }
    let val = next.data.directives[modelDirectiveIndex].value;
    if(val){
        (<HTMLInputElement>el).value = validateDate(val);
    }
})

function validateDate(inputDate: string){
    if(typeof inputDate != 'string'){
        console.error("usa la data come stringa");
        return "";
    }
    let date = new Date(inputDate);
    if(isValidDate(date)){
        let years = date.getFullYear().toString();
        let months = (date.getMonth() + 1).toString();
        if(months.length < 2) months = "0" + months;
        let days = date.getDate().toString();
        if(days.length < 2) days = "0" + days;
        return years +  "-" + months + "-" + days;
    }
}
function isValidDate(d: any) {
    return d instanceof Date && !isNaN(<any>d);
}