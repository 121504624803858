




































import { Component, Vue, Prop } from 'vue-property-decorator';
import * as VM from '@/viewModel';
import * as OM from '@/Model';
import { ChallengeClient, PortafoglioClient } from '@/services/Services';

@Component
export default class CreaPortafoglioModal extends Vue {
    
    @Prop() userIdentifier: string;

    vm: OM.CreateUserBalanceVm = null;
    
    garaSelected: OM.ValueIdentifierOfString = new OM.ValueIdentifierOfString();
    challenges: OM.ValueIdentifierOfString[] = [];

    created(){
        this.vm = {
            userIdentifier: this.userIdentifier,
            nomeGara: '',
            challengeIdentifier: "",
            balance: 100000,
            forceBalance: true,
            fromDate: "",
        };
        ChallengeClient.getNameIdentifier()
        .then(x => {
            this.challenges = x;
        })
    }

    setGara(challenge: OM.ValueIdentifierOfString){
        this.vm.challengeIdentifier = challenge.identifier;
        this.vm.nomeGara = challenge.value;
    }

    creaPortafoglio(){
        PortafoglioClient.createNewBalance(this.vm)
        .then(x => {
            this.$emit('close');
        });
    }

}
