





































import { Component, Vue } from 'vue-property-decorator';
import { CaricamentoFileServices } from '@/services/CaricamentoFileServices';
import * as OM from '@/Model';
import FileSelect from '@/components/fileSelect.vue';
import toastr from 'toastr';
import { SimulationClient } from '@/services/Services';

@Component({
    components: {
        FileSelect
    }
})
export default class CintureView extends Vue {

    fileAssets: any = null;
    untilNavs: string = new Date(2999, 11, 31).toDateString();
    toDateMovimenti: string = new Date().toDateString();
    fromDateDbd: string = new Date().toDateString();
    toDateDbd: string = new Date().toDateString();
    sendPushOnAdvance: boolean = true;


    uploadAssets(){
        CaricamentoFileServices.LoadNavsUntilDate({
            file: this.fileAssets, 
            untilDate: this.untilNavs
        })
        .then(x => {
            toastr.success("Nav caricati")
        })
        .catch(err => {
        });
    }

    uploadAssetsAndAdvance(){
        CaricamentoFileServices.LoadNavsUntilDateAndAdvance({
            file: this.fileAssets, 
            untilDate: this.untilNavs, 
            inviaPush: this.sendPushOnAdvance
        })
        .then(x => {
            toastr.success("Nav caricati")
        })
        .catch(err => {
        });
    }

    processMovimenti(){
        SimulationClient.processMovimenti(this.toDateMovimenti)
    }
    
    processDbd(){
        SimulationClient.advanceDayByDayFromDate(this.fromDateDbd, this.toDateDbd);
    }

}
